@font-face {
  font-family: "Akzidenz-Grotesk BQ";
  src: url("../../fonts/AkzidenzGroteskBQ-BdCnd.eot");
  src: url("../../fonts/AkzidenzGroteskBQ-BdCnd.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/AkzidenzGroteskBQ-BdCnd.woff2") format("woff2"),
    url("../../fonts/AkzidenzGroteskBQ-BdCnd.woff") format("woff"),
    url("../../fonts/AkzidenzGroteskBQ-BdCnd.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TT Commons";
  src: url("../../fonts/TTCommons-Regular.eot");
  src: url("../../fonts/TTCommons-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/TTCommons-Regular.woff2") format("woff2"),
    url("../../fonts/TTCommons-Regular.woff") format("woff"),
    url("../../fonts/TTCommons-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TT Commons";
  src: url("../../fonts/TTCommons-DemiBold.eot");
  src: url("../../fonts/TTCommons-DemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/TTCommons-DemiBold.woff2") format("woff2"),
    url("../../fonts/TTCommons-DemiBold.woff") format("woff"),
    url("../../fonts/TTCommons-DemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TT Commons";
  src: url("../../fonts/TTCommons-Medium.eot");
  src: url("../../fonts/TTCommons-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/TTCommons-Medium.woff2") format("woff2"),
    url("../../fonts/TTCommons-Medium.woff") format("woff"),
    url("../../fonts/TTCommons-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// FONTS unite-quartier 

@font-face {
  font-family: 'Josefin Sans';
  src: url('../../fonts/JosefinSans-Regular.eot');
  src: url('../../fonts/JosefinSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/JosefinSans-Regular.woff2') format('woff2'),
      url('../../fonts/JosefinSans-Regular.woff') format('woff'),
      url('../../fonts/JosefinSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('../../fonts/JosefinSans-SemiBold.eot');
  src: url('../../fonts/JosefinSans-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/JosefinSans-SemiBold.woff2') format('woff2'),
      url('../../fonts/JosefinSans-SemiBold.woff') format('woff'),
      url('../../fonts/JosefinSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('../../fonts/JosefinSans-Bold.eot');
  src: url('../../fonts/JosefinSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/JosefinSans-Bold.woff2') format('woff2'),
      url('../../fonts/JosefinSans-Bold.woff') format('woff'),
      url('../../fonts/JosefinSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Epilogue';
  src: url('../../fonts/Epilogue-Regular.eot');
  src: url('../../fonts/Epilogue-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/Epilogue-Regular.woff2') format('woff2'),
      url('../../fonts/Epilogue-Regular.woff') format('woff'),
      url('../../fonts/Epilogue-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../../fonts/CircularStd-Medium.eot');
  src: url('../../fonts/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/CircularStd-Medium.woff2') format('woff2'),
      url('../../fonts/CircularStd-Medium.woff') format('woff'),
      url('../../fonts/CircularStd-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}



// FIN FONTS UNITE-QUARTIER

@font-face {
  font-family: "icomoon";
  src: url("../../fonts/icomoon.eot?p09pmu");
  src: url("../../fonts/icomoon.eot?p09pmu#iefix") format("embedded-opentype"),
    url("../../fonts/icomoon.ttf?p09pmu") format("truetype"),
    url("../../fonts/icomoon.woff?p09pmu") format("woff"),
    url("../../fonts/icomoon.svg?p09pmu#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-light:before {
  content: "\e900";
}
.icon-arrow-solid:before {
  content: "\e901";
}
.icon-bathroom:before {
  content: "\e902";
}
.icon-bathroom-light:before {
  content: "\e903";
}
.icon-bathroom-solid:before {
  content: "\e904";
}
.icon-broker-access:before {
  content: "\e905";
}
.icon-broker-access-light:before {
  content: "\e906";
}
.icon-broker-access-solid:before {
  content: "\e907";
}
.icon-check:before {
  content: "\e908";
}
.icon-check-light:before {
  content: "\e909";
}
.icon-check-solid:before {
  content: "\e90a";
}
.icon-close:before {
  content: "\e90b";
}
.icon-close-light:before {
  content: "\e90c";
}
.icon-close-solid:before {
  content: "\e90d";
}
.icon-darkmode:before {
  content: "\e90e";
}
.icon-darkmode-light:before {
  content: "\e90f";
}
.icon-darkmode-solid:before {
  content: "\e910";
}
.icon-email:before {
  content: "\e911";
}
.icon-email-light:before {
  content: "\e912";
}
.icon-email-solid:before {
  content: "\e913";
}
.icon-expenses:before {
  content: "\e914";
}
.icon-expenses-light:before {
  content: "\e915";
}
.icon-expenses-solid:before {
  content: "\e916";
}
.icon-facebook:before {
  content: "\e917";
}
.icon-facebook-light:before {
  content: "\e918";
}
.icon-facebook-solid:before {
  content: "\e919";
}
.icon-faceing:before {
  content: "\e91a";
}
.icon-faceing-light:before {
  content: "\e91b";
}
.icon-faceing-solid:before {
  content: "\e91c";
}
.icon-favoritos-heart:before {
  content: "\e91d";
}
.icon-favoritos-heart-light:before {
  content: "\e91e";
}
.icon-favoritos-heart-solid:before {
  content: "\e91f";
}
.icon-favoritos-star:before {
  content: "\e920";
}
.icon-favoritos-star-light:before {
  content: "\e921";
}
.icon-favoritos-star-solid:before {
  content: "\e922";
}
.icon-filters:before {
  content: "\e923";
}
.icon-filters-light:before {
  content: "\e924";
}
.icon-filters-solid:before {
  content: "\e925";
}
.icon-floores-light:before {
  content: "\e926";
}
.icon-floors:before {
  content: "\e927";
}
.icon-floors-solid:before {
  content: "\e928";
}
.icon-gallery:before {
  content: "\e929";
}
.icon-gallery-light:before {
  content: "\e92a";
}
.icon-gallery-solid:before {
  content: "\e92b";
}
.icon-garden:before {
  content: "\e92c";
}
.icon-garden-light:before {
  content: "\e92d";
}
.icon-garden-solid:before {
  content: "\e92e";
}
.icon-grid-view:before {
  content: "\e92f";
}
.icon-grid-view-light:before {
  content: "\e930";
}
.icon-grid-view-solid:before {
  content: "\e931";
}
.icon-instagram:before {
  content: "\e932";
}
.icon-instagram-light:before {
  content: "\e933";
}
.icon-instagram-solid:before {
  content: "\e934";
}
.icon-investment:before {
  content: "\e935";
}
.icon-investment-light:before {
  content: "\e936";
}
.icon-investment-solid:before {
  content: "\e937";
}
.icon-investor-access:before {
  content: "\e938";
}
.icon-investor-access-light:before {
  content: "\e939";
}
.icon-investor-access-solid:before {
  content: "\e93a";
}
.icon-label:before {
  content: "\e93b";
}
.icon-label-light:before {
  content: "\e93c";
}
.icon-label-solid:before {
  content: "\e93d";
}
.icon-lightmode:before {
  content: "\e93e";
}
.icon-lightmode-light:before {
  content: "\e93f";
}
.icon-lightmode-solid:before {
  content: "\e940";
}
.icon-linkedin:before {
  content: "\e941";
}
.icon-linkedin-light:before {
  content: "\e942";
}
.icon-linkedin-solid:before {
  content: "\e943";
}
.icon-location:before {
  content: "\e944";
}
.icon-location-light:before {
  content: "\e945";
}
.icon-location-solid:before {
  content: "\e946";
}
.icon-measure:before {
  content: "\e947";
}
.icon-measure-light:before {
  content: "\e948";
}
.icon-measure-solid:before {
  content: "\e949";
}
.icon-oper-type:before {
  content: "\e94a";
}
.icon-oper-type-light:before {
  content: "\e94b";
}
.icon-oper-type-solid:before {
  content: "\e94c";
}
.icon-orientation:before {
  content: "\e94d";
}
.icon-orientation-light:before {
  content: "\e94e";
}
.icon-orientation-solid:before {
  content: "\e94f";
}
.icon-parking:before {
  content: "\e950";
}
.icon-parking-light:before {
  content: "\e951";
}
.icon-parking-solid:before {
  content: "\e952";
}
.icon-pet:before {
  content: "\e953";
}
.icon-pet-allow-light:before {
  content: "\e954";
}
.icon-pet-allow-solid:before {
  content: "\e955";
}
.icon-phone:before {
  content: "\e956";
}
.icon-phone-light:before {
  content: "\e957";
}
.icon-phone-solid:before {
  content: "\e958";
}
.icon-plus:before {
  content: "\e959";
}
.icon-plus-light:before {
  content: "\e95a";
}
.icon-plus-solid:before {
  content: "\e95b";
}
.icon-pool:before {
  content: "\e95c";
}
.icon-pool-light:before {
  content: "\e95d";
}
.icon-pool-solid:before {
  content: "\e95e";
}
.icon-prop-type:before {
  content: "\e95f";
}
.icon-prop-type-light:before {
  content: "\e960";
}
.icon-prop-type-solid:before {
  content: "\e961";
}
.icon-quote:before {
  content: "\e962";
}
.icon-quote-light:before {
  content: "\e963";
}
.icon-quote-solid:before {
  content: "\e964";
}
.icon-rooms:before {
  content: "\e965";
}
.icon-rooms-light:before {
  content: "\e966";
}
.icon-rooms-solid:before {
  content: "\e967";
}
.icon-row-view:before {
  content: "\e968";
}
.icon-row-view-light:before {
  content: "\e969";
}
.icon-row-view-solid:before {
  content: "\e96a";
}
.icon-search:before {
  content: "\e96b";
}
.icon-search-light:before {
  content: "\e96c";
}
.icon-search-solid:before {
  content: "\e96d";
}
.icon-share:before {
  content: "\e96e";
}
.icon-share-light:before {
  content: "\e96f";
}
.icon-share-solid:before {
  content: "\e970";
}
.icon-toilette:before {
  content: "\e971";
}
.icon-toilette-light:before {
  content: "\e972";
}
.icon-toilette-solid:before {
  content: "\e973";
}
.icon-tour:before {
  content: "\e974";
}
.icon-tour-light:before {
  content: "\e975";
}
.icon-tour-solid:before {
  content: "\e976";
}
.icon-twitter:before {
  content: "\e977";
}
.icon-twitter-light:before {
  content: "\e978";
}
.icon-twitter-solid:before {
  content: "\e979";
}
.icon-whatsapp:before {
  content: "\e97a";
}
.icon-whatsapp-light:before {
  content: "\e97b";
}
.icon-whatsapp-solid:before {
  content: "\e97c";
}
.icon-youtube:before {
  content: "\e97d";
}
.icon-youtube-light:before {
  content: "\e97e";
}
.icon-youtube-solid:before {
  content: "\e97f";
}
.icon-age:before {
  content: "\e980";
}
.icon-age-light:before {
  content: "\e981";
}
.icon-age-solid:before {
  content: "\e982";
}
.icon-aircond:before {
  content: "\e983";
}
.icon-aircond-light:before {
  content: "\e984";
}
.icon-aircond-solid:before {
  content: "\e985";
}
.icon-amenities:before {
  content: "\e986";
}
.icon-amenities-light:before {
  content: "\e987";
}
.icon-amenities-solid:before {
  content: "\e988";
}
.icon-arrow:before {
  content: "\e989";
}
