.Resultados {
    font-family: $font-0;
    padding-bottom: 20vh;
    min-height: 150vh;
    background-color: $secondary-color;
    .header{
        .navbar{
            &::before{
                display: none;
            }
        }
    }
    .order-text {
        color: #4E5F76;
    }
    .select-wrapper {
        position: relative;
        font-family: $font-0;
        i {
            position: absolute;
            right: 14px;
            top: 2px;
            font-size: 1rem;
            color: $primary-color;
            transform: rotate(90deg);
            transition: transform 0.3s;
        }
        &:focus-within {
            i {
                transform: rotate(270deg);
            }
        }
        #resizing_select {
            text-align: left !important;
            margin-right: 0px;
            z-index: 111;
            cursor: pointer;
            width: 8rem;
            // min-width: 13rem!important;
            background-color: transparent;
            all: unset;
            font-weight: 500;
            padding-left: 7px;
            padding-right: 34px;

            position: relative;
            color: $primary-color;
            letter-spacing: 0.3px;
            &::after {
                content: "";
                width: 10px;
                height: 10px;
                position: absolute;
                right: 0;
                top: 0;
                // background-color: red;
            }
        }
    }
    #width_tmp_select {
        display: none;
    }
    .main-resultados {
        padding: 0 !important;
        position: relative;
        .div-header-icono{
            .brand {
                background: $fourth-color;
                width: 190px;
                height:190px;
                z-index: 7;
                display: flex;
                align-content: center;
                justify-content: center;
                .brand__image {
                  height: 54px;
                   margin: auto;
                }
              }
        }
        .main-ventas {
            padding-top: 7rem;
            position: relative;
            z-index: 100;
            // height: 50vh;
            display: flex;
            h5.subheadline-breadcrum {
                margin-top: 2rem;
                color: $primary-color;
                margin-bottom: 8rem;
                text-transform: uppercase;
            }
            h2.title-1 {
                text-transform: uppercase;
                color: $fourth-color;
                // span.regionName.Venta {
                //     color: $primary-color;
                // }
                // span.regionName.Alquiler {
                //     color: $primary-color;
                // }
            }
            .not-results-message {
                // color: $primary-color;
                font-weight: 500;
                display: block;
                text-transform: none;
                margin-top: 2rem;
                font-size: 1.5rem;
            }
            .div-filtrar{
                .div-filtro{
                    .boton-filtro{
                        background-color: transparent;
                        border: none;
                        color: $fourth-color;
                        text-transform: uppercase;
                        i{
                            margin-right: 1rem;
                        }
                    }
                }
            }
        }
    }
    .listing-type {
        font-family: $font-0;
        color: $black;
        display: flex;
        justify-content: center;
        gap: 8px;
        i {
            color: $primary-color;
            padding-top: 4px;
            opacity: 0.5;
            &:hover {
                cursor: pointer;
            }
        }
        .active {
            opacity: 1;
        }
        div {
            font-weight: 500;
        }
    }
    .orderby {
        color: #4E5F76;
        width: 100%;
        line-height: 1;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        letter-spacing: 0.3px;
        button {
            border: none;
            background-color: transparent;
            font-weight: 200;
            i {
                color: $fourth-color;
            }
        }
        select {
            border: none;
            line-height: 1;
            // height: 1rem;
            // font-size: 1rem;
            background-color: transparent;
            font-weight: 500;
            color: $primary-color;
        }
    }
}

@media (max-width: 992px) {
    .Resultados {
        .select-wrapper {
            #resizing_select {
                max-width: 11rem;
            }
        }
        .orderby{
            margin-bottom: 2rem;
        }
        .main-resultados {
            padding: 10rem 0rem 2rem 0rem;
            .main-ventas{
                padding-top: 15rem;
                .div-filtrar{
                    position: fixed;
                    padding: 0.5rem;
                    top: 0;
                    left: 0;
                    right: 0;
                    background-color: $primary-color;
                    z-index: 999;
                    .div-filtro{
                        text-align: center;
                    }
                }
            }
            .div-header-icono{
                display: none;
             
            }
            .btn-filters {
                // background-color: $fourth-color;
                background: $primary-color;
                color: #fff;
                border: none;
                padding: 1rem;
                position: fixed;
                top: 0rem;
                left: 0;
                right: 0;
                z-index: 1000;
                width: 100%;
                transform: 0.4s ease all;
                &.filters-top {
                    position: fixed;
                    top: 0;
                }
            }
            h5 {
                margin-top: 1.5rem;
            }
            h2.title-1 {
                line-height: 1.35 !important;
                margin-bottom: 0;
                margin-top: 3rem;
                span {
                // margin-top: 3rem;
                margin-bottom: 0;
                }
            }
            span {
            }
            h5.subheadline-breadcrum {
                margin-top: 1.5rem;
            }
        }
        .results {
            padding: 0;
        }
    }
}

@media (max-width: 590px) {
    .Resultados {
        .select-wrapper {
            #resizing_select {
                max-width: 11rem;
            }
        }
        .main-resultados {
            padding: 10rem 0rem 2rem 0rem;
            min-height: 50vh;
            .main-ventas {
                min-height: 50vh;
                padding-top: 15rem;
            }
            .btn-filters {
                // background-color: $fourth-color;
                background: $primary-color;
                color: #fff;
                border: none;
                padding: 1rem;
                position: fixed;
                left: 0;
                right: 0;
                z-index: 1000;
                width: 100%;
                transform: 0.4s ease all;
                &.filters-top {
                    position: fixed;
                    top: 0;
                }
            }
            h5 {
                margin-top: 1.5rem;
            }
            h2.title-1 {
                // line-height: 1.35 !important;
                margin-bottom: 0;
                span {
                    margin-bottom: 0;
                }
            }
            span {
            }
            h5.subheadline-breadcrum {
                margin-top: 1.5rem;
            }
        }
        .results {
            padding: 0;
        }
    }
}