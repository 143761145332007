.navbar {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 99999;
  transition: all 1s;

  // display: none;
  .nav li {
    // border-radius: 2px;
    margin-left: .5rem;
    a {
      background-color: transparent !important;
      &:hover :focus{
      background-color: transparent !important;
      }
    }
  }
  .brand{
    // display: none;
    background: #D9D9D9;
    width: 100px;
    height: max-content;
    z-index: 7;
    align-content: center;
    justify-content: center;
    opacity: 0;
    // transition: all 1s;
    &.sticky-nav{
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 190px;
      height: auto;
      transition: all 1s;
      opacity: 1;
      img{
        margin: auto;
      }
    }
  }
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 259px;
    background: transparent
      linear-gradient(
        180deg,
        #000000 0%,
        #000000ea 35%,
        #000000b3 65%,
        #00000000 100%
      )
      0% 0% no-repeat padding-box;
    opacity: 0.4;
  }
  &.sticky-nav{
    position: fixed !important;
    transition: all 1s;
    background-color: #232c38;
    &::before {
      display: none;
    }
  }
}

.nav-link {
  font-family: $font-0;
  letter-spacing: .3px;
  padding: 0.8rem 1.5rem;
  transition: all 0.3s;
  text-transform: uppercase;
  color: $white;
  font-weight: $regular;
  font-size: 1.3rem;
  // background-color: #00000000 !important;
  &:hover {
    color: $primary-color !important;
    background-color: none !important;
  }
  &.active {
    color: $primary-color;
  }
}

@media (max-width: 1550px) {
  .navbar {
    .container-fluid{
      max-width: 95% ;
        .nav-link {
          font-size: 1rem;
        }

    }
    .nav li {
      margin-left: 0;
    }
  }
}

@media (max-width: 1180px) {
  .navbar {
    .container-fluid{
      max-width: 99% ;
        .nav-link {
          font-size: .8rem;
        }

    }
  }
}
