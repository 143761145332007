form {
    .row-field {
        .div_input {
            .form-control {
                margin-top: 1.5rem;
                position: relative;
                // padding: 1rem 2rem !important;
                border: unset !important;
                padding-bottom: 15px !important;
                border-bottom: 1px solid $secondary-color !important;
                background-color: unset !important;
                color: $secondary-color !important;
                font-size: 1.2rem !important;
                border-radius: 0;
                font-family: $font-0;
                font-weight: $bold;
                height: 3.5rem !important;
                letter-spacing: 0.3px;
                &::placeholder {
                    font-family: $font-0;
                    font-weight: $bold;
                    color: $secondary-color !important;

                }
                &:hover {
                    box-shadow: none !important;
                    border: unset;
                    &::placeholder {
                        color: transparent;
                    }
                }
                &:focus {
                    box-shadow: none !important;
                    border: unset;
                    &::placeholder {
                        color: transparent !important;
                    }
                }
            }
            textarea {
                resize: none !important;
                overflow: hidden !important;
            }
        }
    }
    .div_obligatorio{
        p{
            color: $primary-color;
        }
    }
    .boton_contact{
        letter-spacing: 3px;
    }
}