.sidebar__vertical__top{
  height: 100%;
  .brand {
    background: #D9D9D9;
    width: 100%;
    height:190px;
    z-index: 7;
    display: flex;
    align-content: center;
    justify-content: center;
    .brand__image {
      height: 54px;
       margin: auto;
    }
  }
  .text__vertical__top{
    position: relative;
    width: 100%;
    // height: 100%;
    transform: rotate(270deg);
    p{
      color: $white;
      font-family: $font-0;
      font-weight: $regular;
      font-size: 1.2rem;
      letter-spacing: 2.8px;
      // transform: rotate(270deg);
      // position: absolute;
      width: max-content;
      // bottom: -10%;
      // left: -2.2rem;
      margin-left: -100%;
    }
  }
}


.sidebar {
  width: 190px;
  height: 100vh;
    background-color: $secondary-color;
  // background: var(--unnamed-color-1c1c1c) 0% 0% no-repeat padding-box;
  display: none;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  position: absolute;
  // background: #ffffff12;
}

.vertical__title__container {
  height: 20rem;
}

.sidebar__vertical {
  color: var(--primary-color);
  font-size: 1.5vh;
  width: 190px;
  justify-content: center;
  text-align: center;
  .vertical__social__container {
    transform: rotate(270deg);
    display: flex;
    
    &:hover{
      a{

        color: $primary-color !important;
      }
    }
    
    .text__vertical{
      margin-left: 2rem;
      a{
        margin: 0;
        font-family: $font-1;
        font-weight: $regular;
        text-transform: uppercase;
        color: $fourth-color;
        font-size: 1rem;
        letter-spacing: 3.5px;
        margin-top: 5px;
        text-decoration: none;

        &:hover{
          color: $primary-color;
        }
      }
    }
  }
  //   height: 100%;
}

.vertical__title {
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  white-space: nowrap;
  transform: rotate(-90deg);
}

.vertical__social__network{
  width: 100%;
  .vertical__social__container {
    list-style: none;
    padding: 0;
    margin-bottom: 5rem;
    align-items: center;
    .vertical__social__item {
      font-size: 1.5vh;
      line-height: 2rem;
      position: relative;
      z-index: 1;
      a {
        font-size: 1.5rem;
        text-decoration: none;
        color: $white;
        transition: all 0.3s;
        z-index: 2;
        &:hover {
          color: $primary-color;
        }
        i {
          line-height: 2;
        }
      }
      &:first-child{
        transform: rotate(90deg);
        margin-left: 5rem;
      }
    }
  }
}


@media screen and (max-height: 880px) {
  .sidebar__vertical .h-50 {
    height: 40% !important;
  }
}

@media screen and (max-height: 700px) {
  .vertical__title__container {
    visibility: hidden !important;
  }
}

@media (max-width: 1919px) {
  .sidebar__vertical__top{
    .brand {
      .brand__image {
      }
    }
    .text__vertical__top{
  
      p{
        left: -1.9rem;
      }
    }
  }
}

@media (max-width: 1400px) {
  .sidebar__vertical__top{
    .brand {
      .brand__image {
      }
    }
    .text__vertical__top{
  
      p{
        bottom: 10%;
        left: 0.7rem;
        font-size: .8rem;
      }
    }
  }
  .sidebar__vertical .vertical__social__container{

    .text__vertical{
      a{
        font-size: .8rem;
      }
    }
  }
}

