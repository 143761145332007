.novedad {
  .layout {
    .content {
      .div_section_form {
        background-color: $fourth-color;
        .container-fluid {
          .div_news_container {
            border-color: $black;
            // .div_vertical {
            //   p {
            //     color: $primary-color;
            //     background-color: $primary-color;
            //   }
            // }
            .row {
              .div_title {
                border-color: $black;
              }
              .div_first {
                h3 {
                }
                p {
                  color: $tertiary-color;
                }
              }
            }
          }
        }
      }
    }
  }
}
