.div_unidades {
  background: $secondary-color;
  position: relative;
  margin-top: 1rem;
  .collapsed .sub-title {
    opacity: 0;
  }

  h2 {
    color: $fourth-color;
  }
  
  .fluid-2 {
    h3 {
      color: $fourth-color;
    }
    .unities {
      .div_financiacion,
      .div_ambientes {
        .div_tags {
          div{
            width: fit-content !important;

            .tag-filter {
              cursor: pointer;
              border: 1px solid $fourth-color;
              background: transparent !important;
              transition: 0.4s ease all;
              color: $fourth-color;
              min-width: 160px;
              &.active {
                background: $primary-color !important;
                // border: 1px solid;
                color: $white;
              }
              &:hover {
                @media screen and (min-width: 992px) {
                  background: $primary-color !important;
                  // border: 1px solid;
                  color: $white;
                }
              }
            }
            &.disabled {
              .tag-filter.disabled {
                opacity: .6;
                cursor: default!important;
                background: transparent !important;
                color: $fourth-color;
                &:hover {
                  color: $fourth-color;
                  background: transparent !important;
                }
              }
            }
          }
        }
      }
      .div_pisos {
        .unity {
          // padding-top: 0.5rem;
          // padding-bottom: 0.5rem;
          &:hover {
            cursor: pointer;
          }
          &.collapsed {
            .col_arrow {
              i {
                transform: rotate(90deg) !important;
              }
            }
          }
          .head-row {
            .unity-font {
              color: $fourth-color;
            }
            .col_arrow {
              i {
                color: $primary-color;
                transform: rotate(270deg);
              }
            }
          }
          .close-deployable {
            .unity-row {
              transition: .5s all;
              .arrow-direct{
                color: white;
                padding: .3rem;
                border: 2px solid $primary-color;
                border-radius: 50%;
                background-color: $primary-color;
                font-size: .8rem;
                transition: .5s all;
                &:hover{
                  background-color: $white;
                  color: $primary-color;
                  transition: .5s all;
                  border-color: $white;
                }
              }
              .unity-font {
                color: $primary-color;
                  &.unidad {
                    color: $primary-color;
                  }
              }
              &:hover{
                .arrow-direct{
                  background-color: $white;
                  color: $primary-color;
                  transition: .5s all;
                  border-color: $white;

                }
              }
            }
          }
        }
      }
      .div_unidad_inactiva {
        p {
          color: $fourth-color;
          margin-top: 7rem;
          opacity: .6;
        }
      }
    }
  }
}


@media (max-width: 1600px) {
  .div_unidades {
    .fluid-1 {
      p {
        left: -37px;
      }
    }
  }
}

@media (max-width: 1600px) {
  .div_unidades {
    .fluid-1 {
      p {
      }
      h2 {
      }
    }
    .fluid-2 {
      h3 {
      }
      .unities {
        .div_ambientes {
          .div_tags {
            .tag-filter {
              &.active {
              }
            }
          }
        }
        .div_pisos {
          .unity {
            &.collapsed {
              .col_arrow {
                i {
                }
              }
            }
            .head-row {
              .unity-font {
                // font-size: 1.5rem !important;
                font-size: 1.3rem !important;
              }
              .col_arrow {
                i {
                }
              }
            }
            .close-deployable {
              .unity-row {
                .unity-font {
                  // font-size: 1.3rem !important;
                  font-size: 1.1rem !important;
                }
              }
            }
          }
        }
        .div_unidad_inactiva {
          p {
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .div_unidades {
    .fluid-1 {
      p {
        left: -28px;
      }
    }
    .fluid-2 {
      padding: 0rem 1rem 8rem 1rem;
      .unities {
        .div_financiacion,
        .div_ambientes {
          .div_tags {
            justify-content: center;
            .tag-filter {
              &.active {
              }
            }
          }
        }
        .fluid-2 {
          padding: 0rem 1rem 8rem 1rem;
          h3 {
          }
          .unities {
            .div_financiacion,
            .div_ambientes {
              .div_tags {
                justify-content: center;
              }
            }
            .div_pisos {
              .unity {
                &.collapsed {
                  .col_arrow {
                    i {
                    }
                  }
                }
                .head-row {
                  .unity-font {
                  }
                  .col_arrow {
                    i {
                    }
                  }
                }
                .close-deployable {
                  .unity-row {
                    .unity-font {
                      font-size: 1rem !important;
                    }
                  }
                }
              }
            }
            .div_unidad_inactiva {
              p {
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .div_unidades {
    .fluid-2 {
      padding: 0rem 1rem 4rem 1rem;
      .unities {
        .div_financiacion,
        .div_ambientes {
          .div_tags {
            justify-content: center;
            div{
              min-width: 100% !important;
              .tag-filter {
              }
            }
          }
        }
      }
    }
  }
}
