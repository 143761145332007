.Emprendimiento {
    .content {
        .js-projects-gallery {
            .section {
                .row {
                    .first_col {
                        .project {
                            background: transparent !important;
                            a {
                                figure {
                                    .div_zoom {
                                        opacity: 0;
                                        position: absolute;
                                        // border: 1px solid $white;
                                        border-radius: 50%;
                                        top: 0;
                                        left: 0;
                                        bottom: 0;
                                        right: 0;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        transition: .5s all;
                                        i {
                                            transition: .5s all;
                                            color: $white;
                                            font-size: 4rem;
                                            border: 1px solid $white;
                                            padding: 10rem;
                                            border-radius: 50%;
                                        }
                                    }
                                    img {}
                                    figcaption {
                                        .project-category {
                                            // left: 3%;
                                            bottom: 3% !important;
                                        }
                                    }
                                    &:hover {
                                        img {
                                            transform: none !important;
                                            filter: blur(3px);
                                            transform: scale(1.2) !important;

                                        }
                                        .div_zoom {
                                            z-index: 2;
                                            opacity: 1;
                                            // top: 42%;
                                            // left: 38%;
                                            // bottom: 43%;
                                            // right: 38%;
                                            transition: .5s all;
                                            i {
                                                padding: 3rem;
                                            }
                                        }
                                    }
                                    &::before {
                                        content: "";
                                        object-fit: cover;
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        bottom: 0;
                                        right: 0;
                                        width: 100%;
                                        // height: calc(100vh);
                                        height: 100%;
                                        opacity: 0.2;
                                        z-index: 1;
                                        background-color: black;
                                    }
                                }
                            }
                        }
                    }
                    .second_col {
                        .first_child_col {
                            .project {
                                background: transparent !important;
                                a {
                                    figure {
                                        .div_zoom {
                                            opacity: 0;
                                            position: absolute;
                                            // border: 1px solid $white;
                                            border-radius: 50%;
                                            top: 0;
                                            left: 0;
                                            bottom: 0;
                                            right: 0;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            transition: .5s all;
                                            i {
                                                transition: .5s all;
                                                color: $white;
                                                font-size: 4rem;
                                                border: 1px solid $white;
                                                padding: 10rem;
                                                border-radius: 50%;
                                            }
                                        }
                                        img {}
                                        figcaption {
                                            .project-category {
                                                // left: 3%;
                                                bottom: 3% !important;
                                            }
                                        }
                                        &:hover {
                                            img {
                                                transform: none !important;
                                                filter: blur(3px);
                                                transform: scale(1.2) !important;

                                            }
                                            .div_zoom {
                                                z-index: 2;
                                                opacity: 1;
                                                // top: 35%;
                                                // left: 35%;
                                                // bottom: 35%;
                                                // right: 35%;
                                                // top: 39%;
                                                // left: 42%;
                                                // bottom: 40%;
                                                // right: 42%;
                                                transition: .5s all;
                                                i {
                                                padding: 3rem;

                                                }
                                            }
                                        }
                                        &::before {
                                            content: "";
                                            object-fit: cover;
                                            position: absolute;
                                            left: 0;
                                            top: 0;
                                            bottom: 0;
                                            right: 0;
                                            width: 100%;
                                            // height: calc(100vh);
                                            height: 100%;
                                            opacity: 0.2;
                                            z-index: 1;
                                            background-color: black;
                                        }
                                    }
                                }
                            }
                        }
                        .second_child_col {
                            .project {
                                a {
                                    figure {
                                        .div_zoom {
                                            opacity: 0;
                                            position: absolute;
                                            // border: 1px solid $white;
                                            border-radius: 50%;
                                            top: 0;
                                            left: 0;
                                            bottom: 0;
                                            right: 0;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            transition: .5s all;
                                            i {
                                                transition: .5s all;
                                                color: $white;
                                                font-size: 4rem;
                                                border: 1px solid $white;
                                                padding: 10rem;
                                                border-radius: 50%;
                                            }
                                        }
                                        img {}
                                        figcaption {
                                            .project-category {
                                                bottom: 3% !important;
                                                
                                            }
                                        }
                                        &:hover {
                                            img {
                                                transform: none !important;
                                                filter: blur(3px);
                                                transform: scale(1.2) !important;

                                            }
                                            .div_zoom {
                                                z-index: 2;
                                                opacity: 1;
                                                // top: 27%;
                                                // left: 35%;
                                                // bottom: 27%;
                                                // right: 35%;
                                                transition: .5s all;
                                                i {
                                                padding: 3rem;

                                                }
                                            }
                                        }
                                        &::before {
                                            content: "";
                                            object-fit: cover;
                                            position: absolute;
                                            left: 0;
                                            top: 0;
                                            bottom: 0;
                                            right: 0;
                                            width: 100%;
                                            height: calc(100vh);
                                            opacity: 0.2;
                                            z-index: 1;
                                            background-color: black;
                                        }
                                    }
                                }
                            }
                        }
                        .third_child_col {
                            .project {
                                a {
                                    figure {
                                        .div_zoom {
                                            opacity: 0;
                                            position: absolute;
                                            // border: 1px solid $white;
                                            border-radius: 50%;
                                            top: 0;
                                            left: 0;
                                            bottom: 0;
                                            right: 0;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            transition: .5s all;
                                            i {
                                                transition: .5s all;
                                                color: $white;
                                                font-size: 4rem;
                                                border: 1px solid $white;
                                                padding: 10rem;
                                                border-radius: 50%;
                                            }
                                        }
                                        img {}
                                        figcaption {
                                            .project-category {
                                                bottom: 3% !important;
                                            }
                                        }
                                        &:hover {
                                            img {
                                                transform: none !important;
                                                filter: blur(3px);
                                                transform: scale(1.2) !important;
                                            }
                                            .div_zoom {
                                                z-index: 2;
                                                opacity: 1;
                                                // top: 27%;
                                                // left: 35%;
                                                // bottom: 27%;
                                                // right: 35%;
                                                transition: .5s all;
                                                i {
                                                padding: 3rem;
                                                }
                                            }
                                        }
                                        &::before {
                                            content: "";
                                            object-fit: cover;
                                            position: absolute;
                                            left: 0;
                                            top: 0;
                                            bottom: 0;
                                            right: 0;
                                            width: 100%;
                                            height: calc(100vh);
                                            opacity: 0.2;
                                            z-index: 1;
                                            background-color: black;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .div_bottom_arrow {
                    //   transform: rotate(90deg);
                    margin-top: 8rem;
                    z-index: 1;
                    position: relative;
                    a {
                        border: 1px solid $primary-color;
                        border-radius: 49%;
                        padding: 2.2rem;
                        position: absolute;
                        transition: 1s all;
                        span {
                            position: absolute;
                            font-size: 1rem;
                            color: $primary-color;
                            top: 25px;
                            left: 26px;
                            transform: rotate(90deg);
                            transition: 1s all;
                        }
                        &:hover {
                            border-color: $primary-color;
                            span {
                                top: 35px;
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .div_zoom{
        display: none !important;
    }
}