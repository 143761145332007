.div_card_new {
  // width: 100%;
  // height: 100%;
  display: flex;
  justify-content: flex-end;
  // border-radius: 20px;
  overflow: hidden;
  .img_card {
    transition: 1s ease all !important;
    object-fit: cover;
    object-position: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
  .card_new {
    padding: 2rem !important;
    width: 60%;
    // height: 512px;
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    background: #1f1f1f54;
    // border-radius: 0 20px 20px 0;
    .content_card {
      .content_body {
        padding: 0px 1rem 0 0;
        .card_date {
        }
        h3 {
          min-height: 8rem;
        }
        p {
          min-height: 10rem;
        }
      }
      hr {
        height: 2px;
        background: $white;
        opacity: 0.5;
      }
      .publicated_by {
        p {
          color: $primary-color;
        }
      }
      .see_more {
        text-decoration: none;
        align-items: baseline;
        color: $fourth-color;
        text-decoration: none;
        padding: .5rem 1.2rem;
        border: 2px solid $fourth-color;
        @media (max-width: 1400px) {
          font-size: 0.8rem!important;
        }
        &:hover {
          color: $primary-color;
          border: 2px solid $primary-color;
        }
        span.text-hidden {
          @media (max-width: 1411px) {
            display: none;
          }
        }
      }
    }
  }
  &:hover {
    .img_card {
      transform: scale(1.2);
      transition: 2s ease all !important;
    }
    .card_new {
      .content_card {
        .see_more {
          // span {
          //   color: $primary-color;
          // }
          p {
            color: $primary-color;
            margin-left: 1.5rem;
            transition: 1s all;
          }
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .div_card_new {
    .card_new {
      padding: 1.5rem !important;
      .content_card {
        .content_body {
          padding: 0 0 0 0;
          .card_date {
          }
          h3 {
          }
          p {
          }
        }
        .div_pill {
          .pill {
            padding: 0.2rem 1rem;
            margin-right: 0.5rem;
          }
        }
        hr {
        }
        .publicated_by {
          p {
          }
        }
        .see_more {
          span {
          }
          .icon-arrow-light {
          }
        }
      }
    }
    &:hover {
    }
  }
}
