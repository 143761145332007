.div_distrito {
    position: relative;
    padding: 12rem 0rem 12rem 0rem;
    background-color: $secondary-color;
    display: flex;
    .div-first {
        position: relative;
        padding-top: 9rem;
        z-index: 1;
        img {
            width: 80%;
        }
        h4 {
            font-size: 1.5rem;
            color: $fourth-color;
        }
        .div_btn {
            margin-top: 8rem;
            a {
                text-transform: uppercase;
                &:hover {
                    border-color: $primary-color;
                    color: $primary-color;
                }
            }
        }
        &::before {
            content: "";
            position: absolute;
            top: 4rem;
            height: 13px;
            left: 12px;
            right: -10rem;
            background-color: $primary-color;
            z-index: 9;
        }
    }
    .div-second {
        position: relative;
        // background-image: url('../../images/img-home.png');
        // background-size: cover;
        // background-position: center;
        // background-repeat: no-repeat;
        height: 517px;
        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #202020A7;
            opacity: .8;
        }
    }
}

@media (max-width: 992px) {
    .div_distrito {
        .div-first {
            position: relative;
            background-image: url('../../images/ImagenHome.jpeg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: 80vh;
            justify-content: center;
            text-align: center;
            &::after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: #202020A7;
                opacity: .8;
                z-index: -1;
            }
            img {
                align-self: center;
            }
            h4 {}
            .div_btn {}
            &::before {
                top: 11rem;
                height: 13px;
                left: 10px;
                width: 200px;
            }
        }
    }
}