.novedades {
    .layout {
        background-color: $secondary-color;
        .main {
            background-color: $secondary-color;
            padding-top: 10rem;
            padding-bottom: 5rem;
            min-height: fit-content;
            .container-fluid {
                .div_breadcrums {
                    margin-bottom: 10rem;
                }
                .hilera {
                    .div_selected {
                        .div_search {
                            border: 2px solid $primary-color;
                            padding: 1rem 1.5rem;
                            // border-radius: 10px;
                            .div_first_search {
                                .icon-label-solid {
                                    color: $primary-color;
                                    max-width: min-content;
                                }
                                button {
                                    background: transparent;
                                    border: none;
                                }
                            }
                            .div_second_search {
                                button {
                                    i {
                                        position: absolute;
                                        right: 2.25rem;
                                        font-size: 1.5rem;
                                        color: $white;
                                        @media (min-width: 993px) {
                                            top: 1.7rem;
                                        }
                                    }
                                    &.buttonGhostTwo {
                                        border-radius: 0;
                                        font-weight: 700;
                                        transition: all 0.3s;
                                        color: $fourth-color;
                                        font-family: $font-0;
                                        font-size: 20px!important;
                                        background-color: transparent;
                                        border: 2.25px solid $fourth-color;
                                        @media (min-width: 992px) {
                                            padding: 1.5rem 5.25rem 1.5rem 3.5rem;
                                        }
                                        &:hover {
                                            color: $primary-color;
                                            border: 2.25px solid $primary-color;
                                            i {
                                                color: $primary-color;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.div_blog {
    .div_cards-news {
        .div_card_new {
            .card_new {
                .content_card {
                    .div_pill {
                        .pill {
                            font-size: var(--font-size-7)
                        }
                    }
                    .publicated_by > p {
                        font-size: var(--font-size-7)
                    }
                    a.see_more > span.see-more-link,
                    a.see_more > p {
                        font-size: var(--font-size-7)

                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .novedades {
        .layout {
            .main {
                padding-top: 6rem;
                padding-bottom: 3rem;
                .container-fluid {
                    max-width: none;
                    .div_breadcrums {
                        margin-bottom: 3rem;
                    }
                    .hilera {
                        .div_selected {
                            .div_search {
                                padding: 1rem 1.3rem;
                                .div_first_search {
                                    .icon-label-solid {
                                        font-size: 2rem !important;
                                    }
                                    button {}
                                }
                                .div_second_search {
                                    // width: 100%;
                                    // overflow: scroll;
                                    // height: 3rem;
                                    // width: 3rem;
                                    button {
                                        height: 3rem;
                                        padding: 0.5rem 1.7rem 0.5rem 1.7rem;
                                        i {
                                            right: 1rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}