.card__project {
    height: 65vh;
    overflow: hidden;
    position: relative;
    a{
        text-decoration: none;
    }
    .card__project__container {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        z-index: 1;
        &::before{
            content: "";
            position: absolute;
            height: 9px;
            width: 50%;
            bottom: 0;
            background-color: $primary-color;
            z-index: 2;
            transition: all 0.3s;
        }
        // &::after{
        //     content: "+";
        //     position: absolute;
        //     font-size: 5rem;
        //     border-radius: 50%;
        //     border: 2px solid $white;
        //     padding: 2rem;
        //     color: $white;
        //     top: 0;
        //     background-color: transparent;
        //     z-index: 2;
        //     height: 150px;
        //     width: 150px;
        //     text-align: center;
        // }
        .card__project__bg{
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
            img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            z-index: 1;
            }
            &::before{
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: #202020;
                opacity: .4;
                z-index: 2;
            }
        }
        .body-hover{
            transition: all .3s;
            opacity: 0;
            z-index: 3;
            text-transform: uppercase;
            text-decoration: none;
            color: $white;
            margin-top: 20%;
            span{
                border: 2px solid white;
                padding: 3rem;
                font-size: 0rem;
                border-radius: 50%;
                transition: all .3s;
            }
            .card_text{
                margin-top: 5rem;
                transition: all 0.3s;
                p{
                    font-size: 1.5rem;
                    &:last-child{
                        font-size: 1.2rem;
                    }
                }
            }
        }
        .card__title{
            z-index: 1;
            color: $white;
            text-transform: uppercase;
            margin-bottom: 2rem;
            transition: all 0.3s;

        }
        &:hover{
            transition: all 0.5s;
            justify-content: center;
            &::before{
                opacity: .5;
                z-index: 2;
                width: 100%;
                height: 100%;
                transition: all 0.3s;
            }
            .body-hover{
                transition: all .3s;
                opacity: 1;
                span{
                    font-size: 3rem;
                    transition: all 1s;
                }
            }
            .card__title{
                transition: all 0.3s;
                display: none;
            }
        }
    }
    
}


// .card__project {
//     height: 65vh;
//     overflow: hidden;
//     position: relative;
//     .vendido {
//         position: absolute;
//         top: 10rem;
//         padding: 1rem 1rem;
//         right: 2px;
//         z-index: 999999;
//         background: $black;
//         border-radius: 4px 0px 0px 4px;
//         line-height: 1rem;
//         &.disponibles {
//             background-color: $primary-color;
//         }
//     }
//     .ultimas_novedades {
//         position: absolute;
//         top: 0;
//         right: 1rem;
//         background: $primary-color;
//         padding: 2rem 1rem;
//         z-index: 999999;
//         border-radius: 0px 0px 4px 4px;
//         line-height: 1rem;
//     }
// }

// .card__project__container {
//     position: relative;
//     height: 100%;
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: flex-end;
//     //   background-color: var(--primary-color);
// }

// .card__project__bg {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
// }

// .card__project__bg>img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     border: 2px solid #ffffff;
//     border-radius: 5px;
// }

// .card__project__data {
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 100%;
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-direction: column;
//     z-index: 99999;
// }

// .card__project:hover .card__project__data {
//     backdrop-filter: blur(5px);
// }

// .card__project__container::after {
//     content: "";
//     position: absolute;
//     //   background-color: var(--primary-color);
//     width: 100%;
//     height: 100%;
//     opacity: 0;
//     top: 50%;
//     left: 50%;
//     opacity: 0;
//     transition: all 0.3s;
//     z-index: 9999;
//     pointer-events: none;
// }

// .card__project__container::before {
//     content: "";
//     position: absolute;
//     background-color: #333;
//     width: 100%;
//     height: 100%;
//     opacity: 0.5;
//     top: 0%;
//     left: 0%;
//     z-index: 10;
//     //   border: 2px solid #ffffff;
//     border-radius: 5px;
// }

// .card__project:hover .card__project__bg>img {
//     backdrop-filter: blur(9px);
//     -webkit-backdrop-filter: blur(15px);
//     background: #ffffff;
// }

// .card__project:hover .card__project__container::after {
//     top: 0%;
//     left: 0%;
//     transition: all 0.3s;
//     opacity: 0.64;
//     z-index: 99;
// }

// .card__title {
//     position: absolute;
//     bottom: 20px;
//     left: 30px;
//     display: flex;
//     width: 100%;
//     font-size: 1.7rem;
//     z-index: 99;
//     transition: all 0.3s;
//     color: $white;
//     text-transform: uppercase;
// }

// .card__text__card {
//     position: absolute;
//     bottom: 2rem;
//     left: 25px;
//     display: flex;
//     width: fit-content;
//     font-size: 1.8rem !important;
//     z-index: 99;
//     transition: all 0.3s;
//     color: $white;
//     letter-spacing: 1px;
//     transform: rotate(270deg);
//     p {
//         position: absolute;
//         width: max-content;
//         &.enPozo {}
//         &.enConstruccion {}
//     }
// }

// .card__project:hover .card__title {
//     //   opacity: 0;
//     font-size: 5rem !important;
//     transition: all 0.3s;
//     z-index: 99999999999999999;
//     //   color: #fff;
// }

// .card__project:hover .card__text__card {
//     opacity: 0;
//     transition: all 0.3s;
// }

// .card__data__image {
//     width: 100%;
//     height: 100%;
//     align-items: center;
//     transition: 0.5s all;
// }

// .card__project:hover .card__data__image {
//     display: flex;
//     align-content: center;
//     justify-content: center;
//     height: 25%;
//     border: 2px solid white;
//     border-radius: 5px;
//     width: 9rem;
// }

// .card__project:hover .card__data__image>span {
//     transform: scale(1);
//     opacity: 1;
//     transition: all 0.3s;
//     text-align: center;
// }

// .card__project:hover .card__data__text {
//     opacity: 1;
//     transition: all 0.3s;
//     color: #fff;
//     z-index: 99999999999999;
// }

// .card__data__image>span {
//     transform: scale(2);
//     opacity: 0;
//     object-fit: cover;
//     transition: all 0.3s;
//     width: 9rem !important;
//     font-size: 7rem;
//     color: white;
// }

// .card__data__text {
//     position: absolute;
//     text-align: center;
//     left: 35px;
//     top: 120px;
//     opacity: 0;
//     z-index: 99;
//     transition: all 0.3s;
//     //
//     p {
//         color: $white;
//         z-index: 99;
//         text-align: start;
//     }
// }

// .development-zoom {
//     translate: translate(-50%, -50%);
//     top: 50%;
//     left: 50%;
//     z-index: 99;
//     .zoom-image {
//         background-image: url("../../images/signo_mas.svg");
//         background-repeat: no-repeat;
//         background-position: center;
//         height: 10rem;
//         width: 10rem;
//         display: block;
//     }
// }

// @media (max-width: 992px) {
//     .card__project {
//         .vendido {}
//         .ultimas_novedades {}
//     }
// }