.Emprendimiento {
  background-color: $secondary-color;
  .content {
    background-color: $secondary-color;
    .section {
      .container-fluid {
        padding: 17vh 0 6vh 0;
        overflow: hidden;
        position: relative;
        // .div_breadcrumbs {
        //   .breadcrumb {
        //     border-color: $primary-color !important;
        //     .breadcrumb-item {
        //       color: $primary-color !important;
        //       border-color: $primary-color;
        //       a {
        //         color: $primary-color !important;
        //       }
        //       &::before {
        //         color: $primary-color !important;
        //       }
        //     }
        //   }
        // }
        .div_descripcion {
          margin-top: 8rem;
          .first_div {
            h3 {
              color: $primary-color;
              line-height: 1.4;
              letter-spacing: 1px;
            }
            p {
              color: $fourth-color;
            }
          }
          .second_div {
            align-items: center;
            .iconos_descripcion {
              .div_icon {
                display: block;
                align-items: center;
                margin-top: 2rem;
                i {
                  padding: 1rem;
                  font-size: 2rem;
                  color: $primary-color;
                  display: block;
                  border: 1px solid $primary-color;
                  border-radius: 50%;
                  
                }
                span {
                  display: block;
                  color: $fourth-color;
                  text-align: center;
                  text-transform: uppercase;
                  margin-left: 2rem;
                }
              }
            }
          }
          .tertiary_div{
            text-align: center;
            align-items: center;
            img{
              width: 25rem;
              max-width: 100%;
            }
            .button-common{
              text-decoration: none;
              &:hover{
                background-color: $primary-color;
                color: white;
                border-color: $primary-color;
                cursor: pointer;
              }
              div{
                transform: rotate(90deg);
                margin-right: 2rem;
                border-right: 2px solid white;
                display: flex;
                align-self: baseline;
                i{

                }
              }
            }
          }
        }
      }
    }
  }
}

.lading-project-about {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.lading-project-about > div:nth-child(2) {
  padding-left: 3em;
}

.lading-project-about h4 {
  font-size: 1.3rem;
  letter-spacing: 2px;
  margin-top: 5px;
}
@media (max-width: 768px) {
  .lading-project-about {
    grid-template-columns: 1fr;
  }
  .lading-project-about > div:nth-child(2) {
    padding-left: 0;
  }
}
.lading-project-about p {
  font-size: 1rem;
}
.lading-project-about h4 {
  font-size: 1rem;
}

@media (max-width: 1660px) {
  .Emprendimiento {
    .content {
      .section {
        .div_news__title {
        }
        .container-fluid {
          .div_descripcion {
            .first_div {
              h3 {
                // font-size: 1.3rem !important;
              }
              p {
                font-size: 1.2rem !important;
              }
            }
            .second_div {
              .iconos_descripcion {
                .div_icon {
                  i {
                    font-size: 2rem;
                  }
                  span {
                    font-size: 1rem !important;
                  }
                  &:nth-child(2) {
                    i {
                    }
                  }
                }
              }
            }
            .tertiary_div{

              img{
              }
              .button-common{
                font-size: 1rem;
                padding: 1rem 1.5rem;
                div{
                  i{
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .Emprendimiento {
    .content {
      .section {
        .div_news__title {
          h2 {
            &:first-child {
              margin-left: -26rem;
              margin-right: 7rem;
            }
          }
        }
        .container-fluid {
          padding: 17vh 10vw 6vh 10vw;
          .div_descripcion {
          margin-top: 8rem;
            .first_div {
              h3 {
              }
              p {
              }
            }
            .second_div {
              align-items: inherit;
              margin-top: 3rem;
              h2 {
                width: fit-content;
                .circle_desarrollo {
                  top: -2rem;
                  right: -3rem;
                  width: 8rem;
                }
              }
              .iconos_descripcion {
                // width: inherit;
                .div_icon {
                  i {
                  }
                  span {
                  }
                  &:nth-child(2) {
                    i {
                    }
                  }
                }
              }
            }
            .tertiary_div{
              margin-top: 5rem;
              img{}
              .button-common{
                margin-top: 2rem !important;
                width: 100%;
               justify-content: center;
                div{
                  i{
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
