.video {
  background: $secondary-color;
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;

    .div_heard_video {
      h2 {
        color: $fourth-color;
      }
    }
  
}
@media (max-width: 1600px) {
  .video {
    .fluid-1 {
      p {
        left: -22px;
      }
    }
    .fluid-2 {
      .div_video {
        width: 900px;
        height: 500px;
      }
    }
  }
}
@media (max-width: 1300px) {
  .video {
    .fluid-2 {
      padding: 0rem 2rem 8rem 2rem;
      .div_video {
        width: 600px;
        height: 260px;
      }
    }
  }
}
@media (max-width: 992px) {
  .video {
    .fluid-1 {
    }
    .fluid-2 {
      padding: 0rem 2rem 8rem 2rem;
      .div_video {
        width: 400px;
        height: 200px;
      }
    }
  }
}

@media (max-width: 500px) {
  .video {
    .fluid-1 {
    }
    .fluid-2 {
      padding: 0rem 2rem 8rem 2rem;
      .div_video {
        width: 270px;
        height: 150px;
      }
    }
  }
}
