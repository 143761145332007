.main__third__section {
  background-color: var(--secondary-color);
  height: 100%;
  padding: 8rem 0rem;
  position: relative;
}

.main__third__section__container {
  // background-image: url("../../../images/elipse_bg.svg");
}

.main__third__section__article {
  position: relative;
  margin: 5rem 0;
}

.article__image__container {
  overflow: hidden;
}


.main__third__section__article:hover .article__image__container img {
  transform: scale(1.5);
  transition: all .6s;
}

.article__image__container > img {
  transition: all .6s;
  width: 30vw;
}

.article__description__container {
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.article__description__position {
  justify-content: space-between;
  position: absolute;
  top: 21%;
  left: 50%;
  height: 100%

}

.article__position--2 {
  left: 70%;
}

.article__description__title {
  transition: all .6s;
  font-size: 3vw;

}

.text-year {
  font-size: 10vw;
  -webkit-text-stroke: .2vw var(--white);
  color: #00000000;
}

.text-year-primary {
  -webkit-text-stroke: .2vw var(--primary-color);
}

.main__third__section__article:hover
.article__description__title {
  transform: translate(0, -50%);
  transition: all .6s;
}

.bgCircle_nosotros {
  display: none;
  position: absolute;
  top: -5%;
  right: -12%;
  width: 26rem;
}

.bgCircle_nosotros__bottom {
  display: none;
  position: absolute;
  bottom: 2%;
  right: -10vw;
  width: 22rem;
}

@media screen and (max-width: 768px) {
  .main__third__section__article {
    width: 100%;
  }

  .article__image__container > img {
    width: 100%;
  }

  .article__description__position {
    justify-content: space-evenly;
    top: 36%;
    left: 5%;
  }

  .text-size {
    font-size: 15vw;
  }

  .article__description__title {
    font-size: 5vw;
  }

  .article__position--2 {
    left: 0%;
  }
}