.main_contact {
  background-color: $secondary-color;
  padding-top: 5rem;
  .title-ubicacion{
    color: $fourth-color;
    text-transform: uppercase;
    font-size: 2.6rem;
    span{
      color: $primary-color;
    }
  }
  .subtitle-ubicacion{
    color: $fourth-color;
    text-transform: uppercase;
    font-size: 1.7rem;

  }
  .div_map_contact {
    margin-top: 4rem;
    height: 80vh;
  }
}

@media (max-width: 992px) {
  .main_contact {

    .title-ubicacion{
      font-size: 2rem;
      span{
      }
    }
    .subtitle-ubicacion{
    font-size: 1rem;
    }
    .div_map_contact {
    }
  }
}
