.div_ubicacion {
  margin-top: 5rem;
  text-align: center;
  .div_header {
    position: relative;
    text-align: center;
    h2 {
      color: $fourth-color;
    }
    h5 {
      color: $fourth-color;
    }
    .div_news__title {
      position: absolute;
      top: 3rem;
      h2 {
        color: $primary-color;
        opacity: 0.14;
      }
    }
  }
}
@media (max-width: 992px) {
  .div_ubicacion {
    .div_header {
      h2 {
        // font-size: 2.5rem !important;
      }
      h5 {
        // font-size: 1.5rem !important;
      }
      .div_news__title {
        h2 {
        }
      }
    }
  }
}
