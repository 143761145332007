.projects_desarrollo {
    background: #232c38ee;
    position: relative;
    padding: 10rem 0rem 10rem 0rem;
    img{

    }
    h2{
        color: $fourth-color;
        letter-spacing: 12px;
        text-transform: uppercase;
    }
    .div_btn{
        margin-top: 5rem;

        .button-common{
            text-transform: uppercase;
            text-decoration: none;
            &:hover{
                border-color: $primary-color;
                color: $primary-color;
            }
        }
    }
}

@media (max-width: 992px) {
    .projects {
        
    }
}