.buttonGhost,
.buttonGhostTwo {
  position: relative;
  padding: 1.5rem 7rem 1.5rem 4rem;
  color: $white;
  background-color: $primary-color;
  transition: all 0.3s;
  font-size: 20px;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  letter-spacing: 1px;
  a {
    text-decoration: none;
  }
  &:hover {
    background-color: $primary-color;
    text-decoration: none;
    color: $white;
    span {
      i {
        left: 3rem !important;
        transition: 0.5s all;
      }
    }
  }
  span {
    position: relative;
    i {
      position: absolute;
      left: 2rem;
      top: -1rem;
      @media (min-width: 1401px) {
        top: -1.5rem;
      }
    }
  }
}

@media (max-width: 992px) {
  .buttonGhost,
  .buttonGhostTwo {
    padding: 1.5rem 5rem 1.5rem 3rem;
    // font-size: 0.9rem;
  }
}
