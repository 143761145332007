.Resultados {
  position: relative;

    .filter {
      position: absolute;
      left: -2000px;
      &.active{
        left: 0;
        position: fixed !important;
        left: 0;
        position: fixed !important;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(26, 26, 26, 0.342);
        backdrop-filter: blur(5px);
        width: 100%;
        overflow-y: scroll;
        // display: flex;
        // justify-content: center;
        // align-items: center;
      }
      padding-top: 7vh;
      // border: 1px solid $black;
      z-index: 9999;
      width: 36.5vw;
      top: 0;
      transition: 0.4s ease all;
      .div-filter-container{
        // position: relative;
        // background-color: $secondary-color;
        // width: 60%;
        // margin: auto;
        // padding: 7vh 0;
        /* width */
        &::-webkit-scrollbar {
          width: 5px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $secondary-color;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $secondary-color;
        }
        .container-fluid {
            max-width: 100%;
            padding: 0;
            position: relative;
        }
        width: 60%;
        margin: auto;
        background: $secondary-color;
        z-index: 99;
        margin-bottom: 0;
        // padding: 0 0 7vh 0;
        overflow: hidden;
        overflow-y: scroll;
        height: 85vh;
        .filter_header {
          padding: 3rem 3rem;
          position: sticky;
          top: 0;
          background: $secondary-color;
          opacity: 1;
          z-index: 999;
          h2 {
              font-size: 2rem;
              line-height: 1;
              font-family: 'Novecento wide';
              margin: 0;
              font-weight: 500;
          }
          .btn-clear {
            padding: 0;
            text-transform: uppercase;
            border: 0;
            padding-bottom: 3px;
            font-weight: 400;
            border-bottom: 1px solid;
            color: $tertiary-color;
            border: none;
            font-size: 1.2rem;
            background-color: transparent;
            color: $primary-color;
            font-family: $font-1;
            padding: 0 3rem;
            letter-spacing: 2px;
            text-decoration: underline;
            transition: .5s ease all;

            &:hover{
              transition: .5s ease all;

              color: $white;
            }
          }
          .end {
              width: 40px;
              height: 40px;
          }
          .close-filter{
            background-color: transparent;
            border: none;
            .filters-close {
              padding: 10px 0px;
              span {
                  position: relative;
                  transition: 1s ease all;
                  i {
                      transition: .5s ease all;
                      height: 2px;
                      width: 20px;
                      background-color: $primary-color;
                      display: inline-block;
                      position: absolute;
                      top: 0;
                      right: 0;
                      &:nth-child(1) {
                          transform: rotate(-45deg);
                      }
                      &:nth-child(2) {
                          transform: rotate(45deg);
                      }
                  }
                  &:hover{
                    transition: .5s ease all;
                    i{
                      transition: .5s ease all;
                      background-color: $white;
                    }
                  }
              }
            }
          }
          .current-tags {
            margin-top: 0;
            // padding: 0 3rem;
            margin-bottom: 4rem;
            .tag {
              padding: 0.65rem 1.5rem;
              text-transform: capitalize;
              margin-right: 0.5rem;
              margin-bottom: 0.5rem;
              font-size: 1.3rem;
              background-color: $fourth-color;
              border-radius: 0;
              color: $secondary-color;
              font-weight: normal;
              font-family: $font-1;
              // border: 1px solid $primary-color;
              .btn-delete-tag {
                margin-left: 1rem;
                height: 1px;
                width: 14px;
                position: relative;
                cursor: pointer;
                display: block;
                background-color: $secondary-color;
                transform: rotate(-45deg);
                &::before {
                  content: "";
                  height: 1px;
                  width: 14px;
                  position: absolute;
                  display: block;
                  background-color: $secondary-color;
                  transform: rotate(90deg);
                }
              }
              &:hover{
                background-color: $primary-color;
                color: $white;
                .btn-delete-tag{
                  background-color: $white;
                  &::before{
                    background-color: $white;
                  }
                }
              }
            }
          }
        }
      }
      &.open {
        right: 0;
      }
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba($primary-color, 0.7);
      }
      &::-webkit-scrollbar-thumb:hover {
        background: rgba($primary-color, 0.7);
      }
      & hr {
        border-top: solid 3px $fourth-color;
      }
      // &_header {
      //   h3 {
      //     color: $fourth-color;
      //     margin-bottom: 2rem;
      //     background-color: transparent;
      //     padding: 0 3rem;
      //   }
      //   button {
      //     position: absolute;
      //     right: 4rem;
      //     top: 2rem;
      //     border: none;
      //     font-size: 1.2rem;
      //     background-color: transparent;
      //     color: $primary-color;
      //     font-family: $font-1;
      //     padding: 0 3rem;
      //     letter-spacing: 2px;
      //     align-self: flex-start;
      //     text-decoration: underline;
      //   }
      //   .current-tags {
      //     margin-top: 0;
      //     padding: 0 3rem;
      //     margin-bottom: 4rem;
      //     .tag {
      //       padding: 0.65rem 1.5rem;
      //       text-transform: capitalize;
      //       margin-right: 0.5rem;
      //       margin-bottom: 0.5rem;
      //       font-size: 1.3rem;
      //       background-color: $fourth-color;
      //       border-radius: 0;
      //       color: $secondary-color;
      //       font-weight: normal;
      //       font-family: $font-1;
      //       // border: 1px solid $primary-color;
      //       .btn-delete-tag {
      //         margin-left: 1rem;
      //         height: 1px;
      //         width: 14px;
      //         position: relative;
      //         cursor: pointer;
      //         display: block;
      //         background-color: $secondary-color;
      //         transform: rotate(-45deg);
      //         &::before {
      //           content: "";
      //           height: 1px;
      //           width: 14px;
      //           position: absolute;
      //           display: block;
      //           background-color: $secondary-color;
      //           transform: rotate(90deg);
      //         }
      //       }
      //       &:hover{
      //         background-color: $primary-color;
      //         color: $white;
      //         .btn-delete-tag{
      //           background-color: $white;
      //           &::before{
      //             background-color: $white;
      //           }
      //         }
      //       }
      //     }
      //   }
      //   .close-filter {
      //     // border: 2px solid rgba($primary-color, 1);
      //     width: 3.5rem;
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //     vertical-align: middle;
      //     height: 3.5rem;
      //     transition: 0.4s ease all;
      //     padding: 2rem;
      //     position: absolute;
      //     top: 15px;
      //     right: 10px;
      //     span {
      //       width: 1.5rem;
      //       height: 2px;
      //       color: #fff;
      //       background-color: $white;
      //       display: block;
      //       &:nth-child(1) {
      //         transform: rotate(45deg);
      //       }
      //       &:nth-child(2) {
      //         transform: rotate(-45deg) translate(1px, -1px);
      //       }
      //     }
      //     &:hover {
      //       // background-color: $primary-color;
      //       // background: $primary-color;
      //       // border: 2px solid $primary-color;
      //       span {
      //         background-color: $primary-color;
      //         // background-color: transparent;
      //       }
      //     }
      //   }
      // }
      &_body {
        .filters-group {
          border-top: 1px solid $black;
          padding: 0 3rem;
          h3 {
            font-size: 1.6rem;
            color: $fourth-color;
            font-family: $font-0;
            font-weight: $semibold;
            margin-bottom: 0;
            text-transform: uppercase;
          }
          .collapse-btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            span {
              padding: 7px;
              width: 23px;
              height: 23px;
              display: inline-block;
              position: relative;
              font-weight: 500;
              font-size: 0.8rem;
              line-height: 1;
              border-radius: 50%;
              color: $black;
              background-color: transparent;
              border-radius: 7px;
              &:before {
                width: 11px;
                height: 2px;
              }
              &::after {
                height: 11px;
                width: 2px;
              }
              &:before{
                content: "";
                background: $white;
                position: absolute;
                top: 50%;
                left: 54%;
                transform: translate(-50%, -50%);
                transition: 0.3s;
                transform: rotate(220deg);
              }
              &:after {
                content: "";
                background: $white;
                position: absolute;
                top: 30%;
                left: 41%;
                transform: translate(-50%, -50%);
                transition: 0.3s;
                transform: rotate(230deg);
              }
            }
            &.collapsed {
              span {
                &:before {
                transform: rotate(135deg);
                }
                &:after {
                  height: 11px;
                  transform: rotate(315deg);
                }
              }
            }
          }
          input[type="text"] {
            //   border: 1px solid ;
            //   border-radius: 10px;
            padding: 0.8rem 1.7rem;
            background-color: transparent;
            line-height: 1;
            font-size: 1.5rem;
            width: 80%;
            border: 1px solid $primary-color;
            color: $primary-color;
            &::placeholder {
              color: $primary-color;
            }
          }
          .options {
            .content-collapse {
              padding: 1.3rem 0 0.8rem 0;
              .text-danger {
                font-weight: 400;
              }
              &.non-slider {
                .noUi-target {
                  opacity: 0.8;
                  // // cursor: not-allowed
                }
              }
              .noUi-target {
                background: white;
                // margin: 2rem 0;
                border-color: $fourth-color;
              }
              .noUi-horizontal {
                height: 4px;
              }
              .noUi-connect {
                background: $primary-color;
                height: 4px;
                border-color: $primary-color;
              }
              .noUi-connects {
                background-color: grey;
              }
              .noUi-horizontal {
                .noUi-handle {
                  width: 19px;
                  border-radius: 50%;
                  height: 19px;
                  top: -9px;
                  background: $primary-color;
                  border: none;
                  outline: none !important;
                  box-shadow: none;
                  &:after {
                    display: none;
                  }
                  &:before {
                    height: 9px;
                    width: 9px;
                    background: $primary-color;
                    left: 5px;
                    top: 5px;
                    border-radius: 50%;
                  }
                  &.noUi-handle-upper {
                    right: -3px;
                  }
                  &.noUi-handle-lower {
                    right: -17px;
                  }
                }
                .noUi-tooltip {
                  background: transparent;
                  border: none;
                  top: 120%;
                  font-size: 0.8rem;
                }
              }
              .option {
                display: flex;
                margin-bottom: 0.67rem;
                color: $fourth-color;
                font-family: $font-1;
                font-size: 1.3rem;
                letter-spacing: 0.27px;
                cursor: pointer;
                input {
                  display: none;
                  &:checked + span {
                      box-shadow: 0 0 8px $primary-color inset;
                    &:after{
                      background: $primary-color;
                      opacity: .5;
                    }
                    &:before {
                      background: $primary-color;
                      transform: translate(-50%, -50%) scale(1);
                      z-index: 1;
                    }
                  }
                }
                span {
                  display: inline-block;
                  vertical-align: middle;
                  position: relative;
                  margin-right: 0.9rem;
                  width: 22px;
                  height: 22px;
                  border: 1px solid $primary-color;
                  background: transparent;
                  &:before {
                    content: "";
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    transition: 0.3s;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) scale(0);
                  }
                }
                .circle {
                  border-radius: 50%;
                  &::before {
                    border-radius: 50%;
                  }
                }
              }
              .selected {
                color: grey;
              }
            }
            .price-text,
            .surface-text {
              color: $white;
            }
            .btn-rounded {
              background-color: $primary-color;
              color: $white;
              border-radius: 0;
              border: 1px solid $primary-color;
              padding: 24px 78px;
              transition: background-color 0.25s, color 0.25s;
              // font-weight: 600;
              font-size: 1rem;
              text-decoration: none;
              font-family: $font-0;
              &:disabled,
              &:disabled:hover {
                background-color: transparent;
              }
              &:hover {
                background-color: $white;
                color: $primary-color;
              }
            }
            p{
              color: $fourth-color;
            }
          }
        }
      }
    }
    // Cargar más btn - from result-properties.jsx
    .button-common {
      color: $white;
      letter-spacing: 3px;
      &:hover {
        border-color: $primary-color;
        color: $primary-color;
      }
    }
}

@media (max-width: 992px) {
  // Header fixed on mobile - all pages
  header {
    position: fixed;
  }
  .Resultados {
    .filter_header {

    }
    .close-filter {
      display: block;
    }
    .filter {
      background: $fourth-color;
      position: fixed;
      top: 0%;
      bottom: 0;
      left: 200%;
      right: 200%;
      z-index: 9999999;
      max-width: 100%;
      border: none;
      border-radius: 0;
      overflow: scroll;
      padding: 0rem 0rem;
      width: 100%;
      height: 100vh;
      transition: 0.3s ease all;
      &.open {
        right: 0;
        left: 0;
      }
      .filter_body {
        .filters-group {
          h3 {
            font-size: 1.2rem;
          }
          .options .content-collapse .option {
            font-size: 0.85rem;
            letter-spacing: 0.7px;
          }
          &:last-child {
            border-bottom: 1px solid #101010;
          }
        }
      }
      .div-filter-container{
        width: 100%;
        height: 100vh;
        .filter_header{
          .btn-clear{
            padding: 0;
          }
          .end{
            height: 20px;
          }
        }
      }
    }
  }
}
