.propiedad {
    .data {
        background-color: $secondary-color;
        padding-top: 10vh;
        overflow: hidden;
        max-width: 100vw;
        .primer_bloque {
            margin-bottom: 10rem;
            .div_cabecera {
                margin-bottom: 6rem !important;
                // .breadcrumb {
                //     border-color: $primary-color;
                //     .breadcrumb-item {
                //         color: $primary-color !important;
                //         border-color: $primary-color;
                //         a {
                //             color: $primary-color !important;
                //             opacity: 0.7;
                //         }
                //         &:nth-last-child(1) {
                //             color: $primary-color !important;
                //         }
                //     }
                //     .breadcrumb-item+.breadcrumb-item {
                //         border-color: $primary-color;
                //         a {
                //             color: $primary-color !important;
                //         }
                //     }
                // }
            }
            .primera_col {
                max-height: 69vh;
                .no-gutters {
                    .bar-share {
                        .content {
                            .content-share {
                                opacity: 0;
                                transition: .4s all ease;
                                z-index: 1;
                                width: 0;
                                width: fit-content;
                                a {
                                    text-decoration: none;
                                    font-size: 1.5rem;
                                    color: $primary-color;
                                    margin-right: 1rem;
                                    color: $fourth-color;
                                    cursor: pointer;
                                    padding: .5rem;
                                    border: 1px solid $primary-color;
                                    background-color: $primary-color;
                                    &:hover {
                                        background-color: $fourth-color;
                                        color: $primary-color;
                                    }
                                }
                                &.active {
                                    opacity: 1;
                                    z-index: 1;
                                    width: 100%;
                                }
                            }
                            .icon-share {
                                font-size: 1.5rem;
                                color: $fourth-color;
                                cursor: pointer;
                                padding: .5rem;
                                border: 1px solid $primary-color;
                                background-color: $primary-color;
                                margin-right: 4rem;
                                &:hover {
                                    background-color: $fourth-color;
                                    color: $primary-color;
                                }
                                &.active {
                                    background-color: $fourth-color;
                                    color: $primary-color;
                                }
                            }
                        }
                    }
                }
                // .content-share {
                //     opacity: 0;
                //     transition: .4s all ease;
                //     z-index: 1;
                //     width: 0;
                //     a {
                //         display: inline-block;
                //         // background-color: #E5EFFF;
                //         padding: .7rem .7rem;
                //         font-size: .8rem;
                //         color: $primary-color;
                //         box-shadow: none;
                //         border: 1px solid $primary-color;
                //         position: relative;
                //         &::after {
                //             content: '';
                //             background-color: white;
                //             opacity: 1;
                //             position: absolute;
                //             top: 0;
                //             right: 0;
                //             bottom: 0;
                //             z-index: 0;
                //             left: 0;
                //         }
                //     }
                //     &.active-share {
                //         opacity: 1;
                //         z-index: 1;
                //         width: 100%;
                //     }
                // }
            }
            .primera_col,
            .tercera_col {
                h2 {
                    margin-bottom: 0;
                    line-height: 1;
                    color: $fourth-color;
                    &.sub_title {
                        font-weight: 200;
                        color: #fff;
                        margin-top: 10vh;
                    }
                }
                .div-info-prop {
                    span {
                        border-radius: 50px;
                        background-color: $primary-color;
                        color: $white;
                        padding: .5rem 1rem;
                        margin-right: 1rem;
                        &.type-prop {
                            background-color: transparent;
                            border: 1px solid $primary-color;
                            color: $primary-color;
                            &.estreno {
                                color: #fff;
                                border: 1px solid #fff;
                            }
                        }
                    }
                }
                .surface {
                    i {
                        font-size: 2.5rem;
                        padding: 0.7rem;
                    }
                }
                .div_items {
                    .item {
                        color: #fff;
                        text-align: center;
                        .tick {
                            width: 1.5rem;
                            height: 1.5rem;
                            border-radius: 0 !important;
                            i {
                                color: #fff;
                                font-size: 0.5rem;
                            }
                        }
                    }
                    .item-extra {
                        margin-top: 0.5rem;
                        color: $fourth-color;
                        align-items: center;
                        text-transform: uppercase;
                        font-family: $font-0;
                        font-weight: $semibold;
                        div {
                            &::after {
                                content: "|";
                                margin: 0 7px;
                                font-size: 2rem;
                                position: relative;
                            }
                            &:last-child {
                                &::after {
                                    content: "";
                                }
                            }
                        }
                        i {
                            color: $primary-color;
                            text-align: center;
                            font-size: 2rem;
                            border: 1px solid $primary-color;
                            padding: 0.7rem;
                            border-radius: 50%;
                        }
                        .separate-items {
                            margin-left: 1rem;
                            margin-right: 1rem;
                            color: $primary-color;
                        }
                    }
                }
            }
            .segunda_col {
                border: 1px solid $primary-color;
                padding: 4rem;
                p {
                    color: $fourth-color;
                    line-height: 2.2rem;
                    width: 100%;
                    .see_description {
                        color: $primary-color;
                        font-size: 1.5rem;
                    }
                }
                .content-price {
                    display: block;
                    margin: 5rem 0 !important;
                    margin-bottom: 2rem !important;
                    h3 {
                        color: $fourth-color;
                    }
                }
                hr {
                    height: 2px;
                    opacity: 1;
                    color: $primary-color;
                    width: 100%;
                }
            }
        }
        .segundo_bloque {
            background-color: $secondary-color;
            padding-top: 5rem;
            padding-bottom: 5rem;
            .container-fluid {
                width: 90%;
                .amenities {
                    h3 {
                        color: $fourth-color;
                    }
                    .hilera_amenities {
                        .item {
                            color: $fourth-color;
                            font-size: 1.1rem;
                            font-family: $font-0;
                            font-weight: $semibold;
                            text-transform: uppercase;
                            // margin-left: 3rem;
                            i {
                                font-size: 1.3rem;
                                background-color: $primary-color;
                                color: $primary-color;
                                margin-right: 1rem;
                            }
                        }
                    }
                }
            }
        }
        .tercer_bloque {
            padding-top: 10rem;
            padding-bottom: 5rem;
            h2 {
                color: $fourth-color;
            }
            .js-projects-gallery{
                .images-grid-section{
                    .project{
                        .project-plano{
                            width: 100%;
                             height: 100%;
                            figure{
                                height: auto;
                                .div_zoom {
                                    opacity: 0;
                                    position: absolute;
                                    // border: 1px solid $white;
                                    border-radius: 50%;
                                    top: 0;
                                    left: 0;
                                    bottom: 0;
                                    right: 0;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    transition: .5s all;
                                    i {
                                        transition: .5s all;
                                        color: $white;
                                        font-size: 4rem;
                                        border: 1px solid $white;
                                        padding: 10rem;
                                        border-radius: 50%;
                                    }
                                }
                                &:hover {
                                    img {
                                        transform: none !important;
                                        filter: blur(3px);
                                        transform: scale(1.2) !important;

                                    }
                                    .div_zoom {
                                        z-index: 2;
                                        opacity: 1;
                                        // top: 42%;
                                        // left: 38%;
                                        // bottom: 43%;
                                        // right: 38%;
                                        transition: .5s all;
                                        i {
                                            padding: 3rem;
                                        }
                                    }
                                    &::before {
                                        opacity: 0.3;
                                    }
                                }
                                &::before {
                                    content: "";
                                    object-fit: cover;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    bottom: 0;
                                    right: 0;
                                    width: 100%;
                                    // height: calc(100vh);
                                    height: 100%;
                                    opacity: 0.2;
                                    z-index: 1;
                                    background-color: black;
                                }
                            }
                        }
                    }
                }
            }
        }
        .cuarto_bloque {
            h2 {
                color: $fourth-color;
            }
            h5 {
                color: $fourth-color;
            }
        }
        .project {
            width: 100% !important;
            height: 100% !important;
            max-width: 940px !important;
            max-height: 677px !important;
        }
    }
}

@media (max-width: 992px) {
    .propiedad {
        .data {
            .primer_bloque {
                margin-bottom: 0rem;
                .div_cabecera {
                    margin-bottom: 2rem !important;
                    .breadcrumb {
                        .breadcrumb-item {
                            a {}
                            &:nth-last-child(1) {}
                        }
                        .breadcrumb-item+.breadcrumb-item {
                            &::before {}
                            a {}
                        }
                    }
                    .div_news__title {
                        margin-top: -6vh;
                        h2 {
                            &:first-child {
                                margin-left: -24%;
                                margin-right: 5rem;
                            }
                        }
                    }
                }
                .primera_col,
                .tercera_col {
                    h2 {
                        &.sub_title {}
                    }
                    .div_items {
                        padding-left: 1rem;
                        .item {
                            .tick {
                                i {}
                            }
                        }
                        .item-extra {
                            &.surface {}
                            i {}
                            .separate-items {}
                        }
                    }
                }
                .segunda_col {
                    border: none;
                    p {}
                    .content-price {
                        margin: auto !important;
                        margin-top: 2rem !important;
                        h3 {}
                    }
                    hr {}
                }
            }
            .segundo_bloque {
                .container-fluid {
                    .amenities {
                        h3 {
                            margin-top: 10vh;
                        }
                        .hilera_amenities {
                            .item {
                                font-size: 1rem;
                                i {}
                            }
                        }
                    }
                }
            }
            .tercer_bloque {
                .div_news__title {
                    h2 {
                        &:first-child {}
                    }
                }
            }
            .cuarto_bloque {
                .div_news__title {
                    h2 {
                        &:first-child {}
                    }
                }
                h2 {}
                h5 {}
            }
            .project {}
        }
    }
}