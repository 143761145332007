.main__first__section__desarrollos {
  width: 100vw;
  .main__first__section__container__desarrollos {
    position: relative;
    width: 100%;
    .main__first__section__background__desarrollos {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      object-position: center;
    }
    .main__first__section__title__desarrollos {
      position: absolute;
      top: 0%;
      color: var(--white);
      height: 100vh;
      width: 100%;
      .main__first__section__title__container__desarrollos {
        h1 {
          text-transform: uppercase;
          color: $fourth-color;
          padding: 0 15rem 0 0;
          text-align: end;
          z-index: 1;
          width: 55%;
        }
        &::before{
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #202020A7;
          opacity: .8;
        }
      }
    }
  }
}


@media (max-width: 992px){
  .main__first__section__desarrollos {
    .main__first__section__container__desarrollos {
      .main__first__section__title__desarrollos {
        .main__first__section__title__container__desarrollos {
          align-items: center;
          h1 {
            padding: 0;
            text-align: start;
            width: min-content;
            width: 50%;
            
          }
        }
      }
    }
  }
}

@media (max-width: 730px){
  .main__first__section__desarrollos {
    .main__first__section__container__desarrollos {
      .main__first__section__title__desarrollos {
        .main__first__section__title__container__desarrollos {
          h1 {
            width: 90%;
          }
        }
      }
    }
  }
}