@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?vxn289');
  src:  url('fonts/icomoon.eot?vxn289#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?vxn289') format('truetype'),
    url('fonts/icomoon.woff?vxn289') format('woff'),
    url('fonts/icomoon.svg?vxn289#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-email:before {
  content: "\e900";
}
.icon-premium:before {
  content: "\e901";
}
.icon-finaciacion:before {
  content: "\e902";
}
.icon-conectividad:before {
  content: "\e903";
}
.icon-amenities:before {
  content: "\e904";
}
.icon-check:before {
  content: "\e905";
}
.icon-location-icon:before {
  content: "\e906";
}
.icon-quot:before {
  content: "\e907";
}
.icon-house:before {
  content: "\e908";
}
.icon-message:before {
  content: "\e909";
}
.icon-key-icon:before {
  content: "\e90a";
}
.icon-arrow:before {
  content: "\e90c";
}
.icon-arrow-up:before {
  content: "\e90b";
}
.icon-dollar-arrow:before {
  content: "\e90d";
}
.icon-surface-terreno:before {
  content: "\e90e";
}
.icon-sun-theme:before {
  content: "\e90f";
}
.icon-key:before {
  content: "\e910";
}
.icon-share:before {
  content: "\e911";
}
.icon-tick:before {
  content: "\e912";
}
.icon-tour-360:before {
  content: "\e913";
}
.icon-bed:before {
  content: "\e914";
}
.icon-gym:before {
  content: "\e915";
}
.icon-twitter:before {
  content: "\e916";
}
.icon-instagram:before {
  content: "\e917";
}
.icon-facebook:before {
  content: "\e918";
}
.icon-home:before {
  content: "\e919";
}
.icon-location:before {
  content: "\e91a";
}
.icon-arrow-right:before {
  content: "\e91b";
}
.icon-location-solid:before {
  content: "\e91c";
}
.icon-like:before {
  content: "\e91d";
}
.icon-bathtub:before {
  content: "\e91e";
}
.icon-car:before {
  content: "\e91f";
}
.icon-surface:before {
  content: "\e920";
}
.icon-mosaico:before {
  content: "\e921";
}
.icon-lista:before {
  content: "\e922";
}
.icon-picture:before {
  content: "\e923";
}
.icon-investment:before {
  content: "\e924";
}
.icon-leadership:before {
  content: "\e925";
}
.icon-land1:before {
  content: "\e926";
}
.icon-solid-like:before {
  content: "\e927";
}
.icon-filter:before {
  content: "\e928";
}
.icon-3d:before {
  content: "\e929";
}
.icon-logo:before {
  content: "\e92a";
}
.icon-bath:before {
  content: "\e92b";
}
.icon-uniE927:before {
  content: "\e92c";
}
.icon-uniE928:before {
  content: "\e92d";
}
.icon-measure:before {
  content: "\e92e";
}
.icon-ico-sendmail:before {
  content: "\e92f";
}
.icon-picture1:before {
  content: "\e930";
}
.icon-bed1:before {
  content: "\e931";
}
.icon-user:before {
  content: "\e932";
}
.icon-icon-dollar:before {
  content: "\e933";
}
.icon-land:before {
  content: "\e934";
}
.icon-dollar:before {
  content: "\e935";
}
.icon-building:before {
  content: "\e936";
}
.icon-weird-square:before {
  content: "\e937";
}
.icon-icon-graph:before {
  content: "\e938";
}
.icon-icon-star:before {
  content: "\e939";
}
.icon-icon-location:before {
  content: "\e93a";
}
.icon-delete:before {
  content: "\e93b";
}
.icon-search:before {
  content: "\e93c";
}
.icon-tag:before {
  content: "\e93d";
}
.icon-room:before {
  content: "\e93e";
}
.icon-bed2:before {
  content: "\e93f";
}
.icon-bathroom:before {
  content: "\e940";
}
.icon-car1:before {
  content: "\e941";
}
.icon-noun_size_2965801-1:before {
  content: "\e942";
}
.icon-download:before {
  content: "\e943";
}
.icon-equalizer:before {
  content: "\e992";
}
.icon-whatsapp:before {
  content: "\ea93";
}
.icon-whatsapp1:before {
  content: "\ea94";
}
