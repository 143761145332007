.novedades {
  .layout {
    .content {
      .div_blog {
        position: relative;
        // padding: 7rem 0rem 0rem 0rem;
        background-color: $secondary-color;
        display: flex;
        align-items: center;
        .container-fluid {
          margin-right: inherit;
          max-width: 100%;
          // border-left: 2px solid #1c1c1c;
          .div_blog__title {
            position: absolute;
            top: 93rem;
            right: -22rem !important;
            width: max-content;
            height: fit-content;
            transform: rotate(270deg);
            h2 {
              color: $primary-color;
              opacity: 0.14;
              width: max-content;
            }
          }
          .div_blog_container {
            // padding: 0 10rem 10rem 10rem;
            // border-left: 2px solid $secondary-color;
            position: relative;
            margin-top: 3rem;
            // .div_vertical {
            //   p {
            //     transform: rotate(180deg);
            //     margin: 0;
            //     position: absolute;
            //     left: -20px;
            //     writing-mode: tb-rl;
            //     color: $secondary-color;
            //     top: 0rem;
            //     background-color: $white;
            //     padding-top: 3rem;
            //     padding-bottom: 2rem;
            //   }
            // }
            .div_cards-news {
              div {
                .div_card_new {
                  .card_new {
                    width: 40%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1918px) {
  .novedades {
    .layout {
      .content {
        .div_blog {
          .container-fluid {
            .div_blog__title {
              right: -15rem !important;
              h2 {
              }
            }
            .div_blog_container {
              .div_vertical {
                p {
                }
              }
              .div_cards-news {
                div {
                  .div_card_new {
                    .card_new {
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .novedades {
    .layout {
      .content {
        .div_blog {
          .container-fluid {
            .div_blog__title {
              right: -22rem !important;
              h2 {
              }
            }
            .div_blog_container {
              .div_vertical {
                p {
                }
              }
              .div_cards-news {
                div {
                  .div_card_new {
                    .card_new {
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .novedades {
    .layout {
      .content {
        .div_blog {
          .container-fluid {
            .div_blog__title {
              right: -35rem !important;
              h2 {
              }
            }
            .div_blog_container {
              .div_vertical {
                p {
                }
              }
              .div_cards-news {
                div {
                  .div_card_new {
                    .card_new {
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .novedades {
    .layout {
      .content {
        .container-fluid {
          max-width: 100% !important;
          .div_blog {
            .container-fluid {
              .div_blog__title {
                h2 {
                }
              }
              .div_blog_container {
                // padding: 0 0rem 10rem 1rem !important;
                // .div_vertical {
                //   p {
                //     left: -15px;
                //   }
                // }
                .div_cards-news {
                  div {
                    .div_card_new {
                      .card_new {
                        width: 60%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
