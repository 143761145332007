.div_desarrollos {
  position: relative;
  padding: 7rem 0rem 0rem 0rem;
  background-color: $white;
  display: flex;
  align-items: center;

  .container-fluid {
    margin-right: inherit;
    max-width: 90%;
    // border-left: 2px solid #1c1c1c;
    .div_desarrollo__title {
      position: absolute;
      top: 2rem;
      h2 {
        color: $primary-color;
        opacity: 0.14;
        &:first-child {
          margin-left: -23rem;
          margin-right: 23rem;
        }
      }
    }

    .div_desarrollo_container {
      padding: 0 10rem 0 10rem;
      border-left: 2px solid $secondary-color;
      position: relative;
      margin-top: 3rem;
      .div_vertical {
        p {
          transform: rotate(180deg);
          margin: 0;
          position: absolute;
          left: -20px;
          writing-mode: tb-rl;
          color: #1c1c1c;
          top: 0rem;
          background-color: $white;
          padding-top: 3rem;
        }
      }
      .div_dessarrollos_content {
        h2 {
          color: $primary-color;
        }
        h3 {
          color: $primary-color;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .div_desarrollos {
    .div_desarrollo__title {
      h2 {
        &:first-child {
          margin-left: -33rem;
          margin-right: 7rem;
        }
      }
    }
    .container-fluid {
      .div_desarrollo_container {
        padding: 0 0rem 9rem 4rem;
        .div_vertical {
          p {
            left: -14px;
          }
        }
        .div_dessarrollos_content {
          h2 {
            color: $primary-color;
            font-size: 2.3rem !important;
          }
          h3 {
            color: $primary-color;
            font-size: 2rem !important;
          }
        }
      }
    }
  }
}
