.div_news {
  position: relative;
  padding: 10rem 0rem 10rem 0rem;
  background-color: $primary-color;
  display: flex;
  align-items: center;
  .container-fluid {
    .div_btn{
      margin-top: 5rem;
      .button-common{
          text-transform: uppercase;
          text-decoration: none;
          &:hover{
              border-color: $secondary-color;
              color: $secondary-color;
          }
          .hide-text {
            @media (max-width: 576px) {
              display: none;
            }
          }
      }
    }
  }
}
