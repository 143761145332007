.nosotros {
  .content {
    .div_equipo {
      position: relative;
      padding: 7rem 0rem 0rem 0rem;
      background-color: $secondary-color;
      display: flex;
      align-items: center;

      .container-fluid {
        margin-right: inherit;
        max-width: 90%;
        // border-left: 2px solid #1c1c1c;
        .div_equipo_container {
          padding: 0 10rem 10rem 10rem;
          border-left: 2px solid $primary-color;
          position: relative;
          margin-top: 3rem;
          .div_vertical {
            p {
              transform: rotate(180deg);
              margin: 0;
              position: absolute;
              left: -20px;
              writing-mode: tb-rl;
              color: $primary-color;
              top: 0rem;
              background-color: $white;
              padding-top: 3rem;
              padding-bottom: 2rem;
            }
          }
          .div_equipo_content {
            padding-bottom: 7rem;
            .first_title {
              color: $primary-color;
            }
            .second_title {
              color: $primary-color;
              width: 50%;
            }
            .third_title {
              position: relative;
              color: $primary-color;
              max-width: 95%;
              p {
                position: absolute;
                right: -2rem;
                color: $primary-color;
                transform: rotate(90deg);
                top: 2.5rem;
                font-size: 3rem;
              }
            }
          }
          .div_card_personal {
            overflow: hidden;
            padding: 0;
            img {
              object-fit: cover;
              object-position: center;
              width: 100%;
            }
            .info-personal {
              padding: 1rem;
              border: 1px solid $primary-color;
              border-radius: 5px;
              z-index: 2;
              h4 {
                color: $primary-color;
              }
            }
            &:hover {
              img {
                transform: scale(1.2);
                transition: 1s all;
                bottom: 0;
              }
              .info-personal {
                margin-top: 0 !important;
                transition: 1s all;
                margin-bottom: 1.5rem !important ;
                background-color: $white;
              }
            }
          }
        }
      }
    }
    @media (max-width: 1500px) {
      .div_equipo {
        .div_equipo__title {
          h2 {
            &:first-child {
            }
          }
        }
        .container-fluid {
          .div_equipo_container {
            .div_vertical {
              p {
              }
            }
            .div_equipo_content {
              .first_title {
              }
              .second_title {
              }
              .third_title {
                p {
                  top: 1rem;
                }
              }
            }
          }
        }
      }
    }

    @media (max-width: 992px) {
      .div_equipo {
        .div_equipo__title {
          h2 {
            &:first-child {
            }
          }
        }
        .container-fluid {
          .div_equipo_container {
            padding: 0 4rem 0 4rem;
            .div_vertical {
              p {
                left: -14px;
              }
            }
            .div_equipo_content {
              .first_title {
                font-size: 2rem !important;
              }
              .second_title {
                width: 100%;
              }
              .third_title {
                font-size: 2rem !important;
                p {
                  display: none;
                }
              }
            }
            .div_card_personal {
              padding: 0.5rem;
            }
          }
        }
      }
    }
  }
}
