.banner-main {
    height: 100vh;
    background-size: cover;
    background-position: center;
    width: 100%;
    video,
    img {
        width: 100%;
        height: 100vh;
        object-fit: cover;
        object-position: center;
    }
}

#home-main.banner-main {
    &:hover {
        cursor: pointer;
    }
}

#section-galery-home {
    &.with-child {
        #dev-secuencial #dev-gallery #prop-gallery .owl-dots {
            bottom: 13%
        }
        .search-home {
            z-index: 1;
        }
    }
    .link-gallery {
        width: 100%;
        height: calc(100vh);
        display: flex;
        // cursor: pointer;
        background: transparent;
        border: none!important;
    }
}

#section-galery-home {
    height: calc(100vh) !important;
    position: relative;
    &.full-slider {
        height: 100vh !important;
        #dev-secuencial {
            height: calc(100vh) !important;
            .slider {
                .banner-main {
                    min-height: calc(100vh) !important;
                    img {
                        min-height: calc(100vh) !important;
                    }
                }
            }
        }
    }
    #dev-secuencial {
        height: calc(100vh) !important;
        .slider {
            .banner-main {
                min-height: calc(100vh) !important;
                position: relative;
                img {
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    width: 100%;
                    height: calc(100vh) !important;
                    position: relative;
                    z-index: 0;
                }
                &::before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.425);
                    z-index: 1;
                }
                .info-banner {
                    z-index: 2;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    .info {
                        height: 100%;
                        h1 {
                            font-size: 3.2rem !important;
                            font-weight: 500;
                            letter-spacing: 1px;
                        }
                        h2 {
                            font-size: 2rem !important;
                            color: #fff;
                        }
                        h4 {
                            margin-top: 2rem;
                            color: #fff;
                            font-weight: 500;
                            font-size: 1.5rem;
                        }
                    }
                }
            }
            .owl-carousel {
                .owl-dots {
                    position: absolute;
                    bottom: 15%;
                    width: 100%;
                    z-index: 1;
                    justify-content: center;
                    display: flex;
                    .owl-dot {
                        span {
                            width: 15px;
                            height: 15px;
                            position: relative;
                            margin: 5px 7px;
                            // border: 1px solid #fff;
                            display: block;
                            /* -webkit-backface-visibility: visible; */
                            background: white;
                            transition: opacity .2s ease;
                            border-radius: 30px;
                            box-shadow: 0px 0px 3px rgb(39, 39, 39);
                        }
                        &.active {
                            span {
                                background: $primary-color;
                            }
                        }
                    }
                }
            }
            .arrows-owl {
                top: 46%;
                left: 4%;
                right: 4%;
                .arrow {
                    background-color: transparent;
                    .next {
                        i {
                            background: transparent;
                            &:nth-child(1) {
                                transform: rotate(0deg);
                                top: 0;
                            }
                            &:nth-child(2) {
                                transform: rotate(0deg);
                                top: 0;
                            }
                        }
                    }
                    &:nth-child(1) {
                        .next {
                            transform: rotate(180deg);
                            padding-top: 9px;
                        }
                    }
                }
            }
        }
        .center-text {
            width: 85%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            p {
                color: #fff;
                line-height: 1.4;
            }
            h1 {
                font-size: 1.4rem;
                font-weight: 500;
                line-height: 1.4;
                color: #fff;
            }
        }
    }
}

@media (max-width:992px) {
    #section-galery-home {
        #dev-secuencial {
            .slider {
                .banner-main {
                    .info-banner {
                        .info {
                            h1 {
                                font-size: 2rem;
                                letter-spacing: 1px;
                            }
                            h4 {
                                margin-top: 2rem;
                                font-size: 1.3rem;
                            }
                        }
                    }
                }
                .owl-carousel {
                    .owl-dots {
                        .owl-dot {
                            display: none;
                        }
                    }
                }
                .arrows-owl {
                    top: 46.6%;
                    .arrow {
                        .next {
                            i {
                                &:nth-child(1) {}
                                &:nth-child(2) {}
                            }
                        }
                        &:nth-child(1) {
                            .next {
                                padding-top: 9px;
                            }
                        }
                    }
                }
            }
        }
    }
}