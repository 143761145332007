.buttonAnimation {
    height: 4rem;
    background-color: $primary-color;
    border-radius: 5px;
    transition: all 0.3s;
    outline: unset;
    border: unset;
    color: $white;
    padding: 1rem 2.5rem;
    letter-spacing: 1.5px;
    &:hover {
        background-color: $primary-color;
    }
    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
}

.ButtonAnimationPrimary {
    background-color: $primary-color !important;
    border: 1px solid #fffaf5 !important;
    margin: 0 1rem;
}

@media (max-width: 992px) {
    .buttonAnimation {
        padding: 0 1rem;
        height: 3rem;
        font-size: 0.8rem;
        margin-top: 1rem;
    }
}