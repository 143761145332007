:root {
    --primary-color: #A1264F;
    // --secondary-color: #151a2f;
    --secondary-color: #232C38;
    --btn-primary-color: #d39d2a;
    --btn-secondary-color: #ac842d;
    --btn-primary-border: #d39d2a;
    --link-footer: #1caff0;
    --white: #ffffff;
    --links-color: #fffaf5;
    --aera-color: #99b6df;
    --campos-color: #3f5b42;
    --cerrito-color: #a361bf;
    --cerritomio-color: #ac2244;
    --cochabamba-color: #e54c29;
    --rivadavia-color: #61bfa6;
    --cantilo-color: #adbbff;
    --pareja-color: #3b57e5;
    --primary-bg: #e5833b;
    /* font-sizes */
    --navbar-links-size: 14px;
    --main-title-size: 40px;
    --main-title-our-devs: 50px;
    --main-text-size: 16px;
    --btn-text-size: 20px;
    --main-card-text-size: 23px;
    --font-size-1: 3.5rem;
    --font-size-2: 2.8rem;
    --font-size-3: 2.6rem;
    --font-size-4: 2.3rem;
    --font-size-5: 1.9rem;
    --font-size-6: 1.5rem;
    --font-size-7: 1.4rem;
    --font-size-8: 1.3rem;
    --font-size-9: 1.2rem;
    --font-size-10: 1.1rem;
    --font-size-11: 1rem;
    --font-size-12: .9rem;
    --font-size-13: .8rem;
    --font-size-14: .9rem;

    //   font-family
    --first-font: ;
    --second-font: ;
}

//  colores Unite-Quartier
$primary-color: #A1264F;
$secondary-color: #232C38;
$tertiary-color: #4E5F76;
$fourth-color: #D9D9D9;
$white: #ffffff;
$black: #000000;

//Fuentes
$font-0: 'Josefin Sans';
$font-1: 'Epilogue';
$regular: normal;
$semibold: 600;
$bold: bold;


// Fuentes : FAMILY CLASES
.font-0 {
    font-family: $font-0 !important;
}

.font-1 {
    font-family: $font-1 !important;
}
.text-regular {
    font-weight: $regular !important;
}
.text-semibold {
    font-weight: $semibold !important;
}
.text-bold {
    font-weight: $bold !important;
}

// Fuentes : FONT_SIZE CLASES


.fs-1 {
    font-size: var(--font-size-1) !important;
}

.fs-2 {
    font-size: var(--font-size-2) !important;
}
.fs-3 {
    font-size: var(--font-size-3) !important;
}
.fs-4 {
    font-size: var(--font-size-4) !important;
}
.fs-5 {
    font-size: var(--font-size-5) !important;
}
.fs-6 {
    font-size: var(--font-size-6) !important;
}
.fs-7 {
    font-size: var(--font-size-7) !important;
}
.fs-8 {
    font-size: var(--font-size-8) !important;
}
.fs-9 {
    font-size: var(--font-size-9) !important;
}
.fs-10 {
    font-size: var(--font-size-10) !important;
}
.fs-11 {
    font-size: var(--font-size-11) !important;
}
.fs-12 {
    font-size: var(--font-size-12) !important;
}
.fs-13 {
    font-size: var(--font-size-13) !important;
}
.fs-14 {
    font-size: var(--font-size-14) !important;
}


// CLASES


body {
    color: var(--white);
}

.link-footer {
    color: var(--link-footer);
}

.text-primary {
    color: var(--primary-color) !important;
}

@media (max-width: 1500px) {
}

@media (max-width: 1400px) {

    .fs-1 {
        font-size: var(--font-size-2) !important;
    }
    
    .fs-2 {
        font-size: var(--font-size-3) !important;
    }
    .fs-3 {
        font-size: var(--font-size-5) !important;
    }
    .fs-4 {
        font-size: var(--font-size-5) !important;
    }
    .fs-5 {
        font-size: var(--font-size-6) !important;
    }
    .fs-6 {
        font-size: var(--font-size-7) !important;
    }
    .fs-7 {
        font-size: var(--font-size-10) !important;
    }
    .fs-8 {
        font-size: var(--font-size-9) !important;
    }
    .fs-9 {
        font-size: var(--font-size-10) !important;
    }
    .fs-10 {
        font-size: var(--font-size-11) !important;
    }
    .fs-11 {
        font-size: var(--font-size-12) !important;
    }
    .fs-12 {
        font-size: var(--font-size-13) !important;
    }
    .fs-13 {
        font-size: var(--font-size-14) !important;
    }
    .fs-14 {
        font-size: var(--font-size-14) !important;
    }

}