.nosotros {
  .content {
    .div_trayectoria {
      position: relative;
      padding: 7rem 0rem 0rem 0rem;
      background-color: $secondary-color;
      display: flex;
      align-items: center;
      .container-fluid {
        max-width: 80%;
        &.fluid-body{
          padding: 9rem 0 9rem 0;
          max-width: 65%;
          margin: auto;
          .div-img-person{
            background-image: url("../../images/person2.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: 57vh;
          }
          .div-info-person{
            position: relative;
            .div-box{
              position: absolute;
              left: -3.5rem;
              top: 12rem;
              .name-person{
                color: $fourth-color;
                font-size: 3rem;
              }
              .box-person{
                border: 2px solid $fourth-color;
                padding: 1.2rem;
                align-items: flex-end;
                text-decoration: none;
                transition: .3s ease all;
                i{
                  color: $fourth-color;
                  font-size: 1.7rem;
                  align-self: center;
                  transition: .3s ease all;
                }
                h3{
                  letter-spacing: 3px;
                  color: $white;
                  margin: 0;
                  font-size: 1.2rem;
                  transition: .3s ease all;
                  margin-top: .2rem;
                }
                &:hover{
                  border-color: $primary-color;
                  transition: .3s ease all;
                  i{
                    color: $primary-color;
                    transition: .3s ease all;
                  }
                  h3{
                    color: $primary-color;
                    transition: .3s ease all;
                  }
                }
              }
              &::before{
                content:"";
                position: absolute;
                background-color: $primary-color;
                width: 222px;
                height: 18px;
                top: -4rem;
              }
            }
          }
        }
        &.fluid-parrafo{
            // padding: 0 0 4rem 0;
            max-width: 65%;
            margin: auto;
          .div-descripcion-trayectoria{
            position: relative;
            width: fit-content;
            padding: 2rem;
            &::before{
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 5rem;
              height: 5rem;
              border-top: 3px solid $primary-color;
              border-left: 3px solid $primary-color;
            }
            &::after{
              content: "";
              position: absolute;
              bottom: 0;
              right: 0;
              width: 5rem;
              height: 5rem;
              border-bottom: 3px solid $primary-color;
              border-right: 3px solid $primary-color;
            }
            .primer_parrafo{
              color: $fourth-color;
              letter-spacing: .8px;
              padding: 0;
              margin: 0;
            }
            .ver_mas{
              text-transform: uppercase;
              letter-spacing: 2.55px;
              color: $primary-color;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1700px) {
  .nosotros {
    .content {
      .div_trayectoria {
        .container-fluid {
          &.fluid-body{
            .div-img-person{
            }
            .div-info-person{
              .div-box{
                
                .name-person{
                  font-size: 2.5rem;
                }
                .box-person{
                  width: max-content;
                  i{
                  }
                  h3{
                    font-size: 1.1rem;
                  }
                }
                &::before{
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1300px) {
  .nosotros {
    .content {
      .div_trayectoria {
        .container-fluid {
          &.fluid-body{
          max-width: 80%;
            .div-img-person{
              height: 64vh;
            }
            .div-info-person{
              .div-box{
                .name-person{
                }
                .box-person{
                  i{
                  }
                  h3{
                  }
                }
                &::before{
                }
              }
            }
          }
          &.fluid-parrafo{
            max-width: 80%;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .nosotros {
    .content {
      .div_trayectoria {
        .container-fluid {
          &.fluid-body{
            .div-img-person{
              height: 385px;
            }
            .div-info-person{
              position: relative;
              background-image: url("../../images/person2.jpg");
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              height: 40vh;
              justify-content: end;
              padding-bottom: 2rem;
              .div-box{
                position: relative;
                top: 0;
                left: 0;
                .name-person{
                  font-size: 2rem;
                }
                .box-person{
                  padding: 0.7rem;
                  i{
                    font-size: .8rem;
                  }
                  h3{
                    font-size: .8rem;
                    text-align: left;
                  }
                }
                &::before{
                  width: 100%;
                  height: 5px;
                  top: -2rem;
                }
              }
              &::before{
                position: absolute;
                content: "";
                width: 100%;
                left: 0;
                bottom: 0;
                height: 127px;
                background: transparent linear-gradient(0deg, #000000 0%, #000000ea 35%, #000000b3 65%, #00000000 100%) 0% 0% no-repeat padding-box;
                opacity: 0.4;
              }
            }
          }
          &.fluid-parrafo{
            padding: 0 0 5rem 0;
          }
        }
      }
    }
  }
}
