#caracteristicas{
  &.details {
    background: $secondary-color;
    position: relative;
    margin-top: 10rem;
    .div-text{
      position: relative;
      color: $fourth-color;
      font-family: $font-1;
      font-size: 1.7rem;
      padding: 5rem;
      height: min-content !important;
      z-index: 1;
      &::after{
        content: "";
        position: absolute;
        background-color: transparent;
        width: 200px;
        height: 200px;
        border-left: 15px solid $primary-color;
        border-top: 15px solid $primary-color;
        top: 0; 
        left: 0;
      }
      &::before{
        content: "";
        position: absolute;
        background-color: transparent;
        width: 200px;
        height: 200px;
        border-right: 15px solid $primary-color;
        border-bottom: 15px solid $primary-color;
        bottom: 0; 
        right: 0;
      }
    }
    .first-div{
      min-height: 80vh;
      margin-top: 6rem;
      margin-bottom: 10rem;
      align-items: center;
      z-index: 1;
      .div-img{
        position: relative;
        height: 80vh;
        img{
          position: absolute;
          top: 5vh;
          width: 70vh;
          height: 70vh;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
          left: -14%;
          z-index: 0;
          border: 1px solid #707070;
        }
        &::before{
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: -14%;
          right: 0;
          background-color: rgb(63, 63, 63);
          top: 5vh;
          opacity: .3;
          border-radius: 50%;
          width: 70vh;
          height: 70vh;
          z-index: 1;
        }
      }
      .div-text{
        z-index: 2;

      }
    }
    .second-div{
      min-height: 60vh;
      margin-top: 6rem;
      margin-bottom: 10rem;
      align-items: flex-start;
      z-index: 1;
      .div-img{
        position: relative;
        height: 60vh;
        img{
           position: absolute;
          top: 5vh;
          width: 90vh;
          height: 55vh;
          object-fit: cover;
          object-position: center;
          left: 0;
          z-index: 0;
          border: 1px solid #707070;
        }
        &::before{
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgb(63, 63, 63);
          top: 5vh;
          opacity: .3;
          width: 90vh;
          height: 55vh;
          z-index: 1;
        }
      }
      .div-text{
        z-index: 2;
        &::after{
          border-right: 15px solid $primary-color;
          border-top: 15px solid $primary-color;
          right: 0 ;
          border-left: none;
          left: initial;
        }
        &::before{
          border-left: 15px solid $primary-color;
          border-bottom: 15px solid $primary-color;
          left: 0;
          border-right: none;
        }
      }
    }
    .tertiary-div{
      min-height: 80vh;
      margin-top: 6rem;
      margin-bottom: 10rem;
      align-items: center;
      z-index: 1;
      .div-img{
        position: relative;
        height: 80vh;
        img{
          position: absolute;
          top: 5vh;
          width: 70vh;
          height: 70vh;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
          left: 15%;
          z-index: 0;
          border: 1px solid #707070;
        }
          &::before{
            content: "";
            position: absolute;
            top: 5vh;
            bottom: 0;
            left: 15%;
            right: 0;
            background-color: rgb(63, 63, 63);
            opacity: .3;
            border-radius: 50%;
            width: 70vh;
            height: 70vh;
            z-index: 1;
          }
        }
        .div-text{
          z-index: 2;
  
        }
    }
  }
}

@media (max-width: 992px) {
  #caracteristicas{
    &.details {
      margin-top: 7rem;
      .div-text{
        font-size: 1rem;
        padding: 4rem;
        &::after{
          width: 60px;
          height: 60px;
          border-left: 7px solid $primary-color;
          border-top: 7px solid $primary-color;
          top: 10px;
          left: 10px;
        }
        &::before{
          width: 60px;
          height: 60px;
          border-right: 7px solid $primary-color;
          border-bottom: 7px solid $primary-color;
          right: 10px;
           bottom: 10px;
        }
      }
      .first-div{
          min-height: 25vh;
        .div-img{
          height: 0vh;
          img{
            top: -23vh;
            width: 20rem;
            height: 20rem;
            border-radius: 50%;
            left: 80%;
          }
          &::before{
            top: -23vh;
            width: 20rem;
            height: 20rem;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
            left: 80%;
            opacity: .6;
            background-color: $black;
          }
        }
        .div-text{
        }
      }
      .second-div{
        .div-img{
          height: 0vh;
          img{
            width: 100%;
            height: 60vh;
            top: 0;
          }
          &::before{
            height: 60vh;
            top: 0;
            background-color: $black;
            opacity: .6;
          }
        }
        .div-text{
          &::after{
            width: 60px;
            height: 60px;
            border-right: 7px solid $primary-color;
            border-top: 7px solid $primary-color;
            top: 10px;
            right: 10px;
          }
          &::before{
            width: 60px;
            height: 60px;
            border-left: 7px solid $primary-color;
            border-bottom: 7px solid $primary-color;
            left: 10px;
            bottom: 10px;
          }
        }
      }
      .tertiary-div{
        min-height: auto;
        margin-bottom: 15rem;
          .div-img{
            height: 0vh;
            img{
              top: -20px;
              width: 20rem;
              height: 20rem;
              border-radius: 50%;
              left: 80%;
            }
            &::before{
              top: -20px;
              width: 20rem;
              height: 20rem;
              border-radius: 50%;
              object-fit: cover;
              object-position: center;
              left: 80%;
              opacity: .6;
              background-color: $black;
            }
          }
        .div-text{    
        }
      }
    }
  }
}
