.Emprendimiento,
.propiedad .data {
    .contact-main {
        padding: 10vh 0;
        background-color: #0F0F0F;
        h2 {
            font-size: 5rem;
            font-weight: 200;
            color: #fff;
        }
        .content-contact {
            width: 100%;
            span,
            a {
                width: 100%;
                font-size: 1.4rem;
                margin-bottom: .5rem;
                color: #C3C3C3;
                font-weight: 200;
                border-bottom: none;
            }
            .line-phone {
                width: 5rem;
                margin-left: 1rem;
                margin-right: 1rem;
                height: 1px;
                background-color: #fff;
                display: inline-block;
            }
        }
        form {
            padding-top: 10vh;
            padding-bottom: 10vh;
            textarea,
            input {
                border: 1px solid var(--primary-color);
                color: #fff;
                &::placeholder {
                    color: var(--primary-color);
                }
            }
            p {
                font-size: .9rem;
            }
            textarea {
                height: 100%;
            }
        }
    }
}

@media (max-width:992px) {
    .Emprendimiento,
    .propiedad .data {
        .contact-main {
            padding: 10vh 0;
            h2 {
                font-size: 2.5rem;
                margin-bottom: 2rem;
            }
            .content-contact {
                width: 100%;
                span,
                a {
                    font-size: 1.1rem;
                    margin-bottom: .6rem;
                }
                .line-phone {
                    width: 15vw;
                }
            }
            form {
                padding: 5vh 0 !important;
                textarea {
                    height: 100%;
                }
                .btn-square {
                    width: 100%;
                }
                p {
                    font-size: .8rem;
                    margin-bottom: 2rem;
                }
            }
        }
    }
}