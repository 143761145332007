.div_section_form {
  position: relative;
  padding: 9rem 0rem 9rem 0rem;
  background-color: $fourth-color;
  display: flex;
  align-items: center;
  .container-fluid {
    // max-width: 90%;
    .div_news_container {
      position: relative;
      .div_title {
        padding-bottom: 4rem;
        margin-bottom: 4rem;
        position: relative;
        h2 {
          color: $secondary-color;
        }
        .section__five__arrow__top {
          position: absolute;
          right: 0;
          top: -1rem;
          cursor: pointer;
          z-index: 9999999;
          transform: rotate(270deg);
          text-decoration: none;
          i {
              font-size: 5rem;
              color: $primary-color;
          }
          &:hover {
              top: 1.5rem;
              transition: 1.5s all;
              i {
                  color: $secondary-color;
              }
          }
      }
      }
      .div_first {
        p {
          color: $tertiary-color;
          margin-bottom: 0;
          line-height: 2;
        }
        a{
          color: $primary-color;
          text-decoration: none;
          &:hover{
            color: $secondary-color;
          }
        }
      }
      .div_second {
        .boton_contact{
          &:hover{
            // border-color: $primary-color;
            // color: $primary-color;
            border-color: $fourth-color;
            color: $fourth-color;
          }
        }
      }
    }
  }
}
@media screen and( min-width: 728px) {
}

@media (max-width: 992px) {
  .div_section_form {
    padding: 9rem 0rem 4rem 0rem;
    
    .container-fluid {
      .div_news_container {
        padding: 0 1rem 2rem 2rem;
        .div_vertical {
          p {
          }
        }
        .div_title {
          padding-bottom: 2rem;
          margin-bottom: 2rem;
          h2 {
          }
          .section__five__arrow__top{
            top: -7rem;
          }
        }
        .div_first {
          margin-top: 4rem;
          padding-bottom: 4rem;
          // margin-bottom: 2rem;
          h3 {
          }
          p {
          }
        }
        .div_second {
          .boton_contact{
            width: 100%;

          }
        }
      }
    }
  }
}
