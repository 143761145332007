.main__second__section__contacto {
  background-color: var(--secondary-color);
  padding: 8rem 0 0 0;
}

.main__map__image {
  width: 100%;
}

@media screen and(min-width: 1024px) {
  .main__map__image {
    max-width: 50vw;
  }
}