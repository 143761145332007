.novedad {
    .layout {
        background-color: $secondary-color;
        .main {
            .rdw-editor-toolbar {
                display: none !important;
            }
            .rdw-image-center {
                display: block;
            }
            .rdw-image-alignment-options-popup {
                display: none !important;
            }
            .rdw-editor-main {
                font-family: var(--first-font) !important;
                a span {
                    text-decoration: underline 1px solid $primary-color !important;
                    color: $primary-color !important;
                }
                span {
                    background-color: transparent !important;
                    font-size: 1.2rem;
                    color: $fourth-color !important;
                }
                figure {
                    iframe {
                        width: 100%;
                        height: 50vh;
                    }
                    span.rdw-image-alignment.rdw-image-center {
                        span.rdw-image-imagewrapper {
                            img {
                                width: 100%;
                                height: 50vh;
                            }
                        }
                    }
                }
            }
            .public-DraftStyleDefault-block {
                margin: .5rem 0 !important;
            }
            .container-fluid-85 {
                padding-left: 196px;
                // @media (max-width: 767px) {
                //     padding-left: 176px;
                // }
                .primer_bloque {
                    background-color: $secondary-color;
                    .div_breadcrums {}
                    .div_novedad {
                        padding-left: 4rem;
                        padding-right: 5rem;
                        // border-left: 2px solid $primary-color;
                        padding-bottom: 5rem;
                        @media (max-width: 767px) {
                            border-left: 0;   
                        }
                        .texto_lateral {
                            color: $primary-color;
                            background: $white;
                            position: absolute;
                            transform: rotate(270deg);
                            left: -6.3rem;
                            top: 14rem;
                            padding-left: 2rem;
                            padding-right: 2rem;
                        }
                        .fecha {
                            color: $fourth-color;
                        }
                        .banner-container {
                            display: flex;
                            max-width: 100%;
                            flex-direction: column;
                            .banner-image {
                                height: 50vh;
                                display: flex;
                                object-fit: cover;
                            }
                            .banner-caption {
                                opacity: .8;
                                color: $primary-color;
                                padding: 0.5rem;
                                font-size: .9rem;
                                transition: all .3s;
                                align-self: flex-end;
                            }
                        }
                        .titulo {
                            color: $primary-color;
                        }
                        .subtitulo {
                            opacity: 0.6;
                            color: $fourth-color;
                        }
                        .publicado_por {
                            color: $fourth-color;
                        }
                        .texto_novedad {
                            color: $fourth-color;
                        }
                        .div_video {
                            height: 393px;
                            width: 100%;
                            background-color: $secondary-color;
                        }
                        .texto_novedad_2 {
                            color: $primary-color;
                        }
                        .hr_novedad {
                            height: 2px;
                            width: 100%;
                            color: $primary-color;
                            opacity: 1;
                        }
                        .pills {
                            margin-bottom: 2.5rem;
                            .pill {
                                font-size: var(--font-size-7)
                            }
                        }
                        .button-common {
                            display: flex;
                            font-size: 14px;
                            width: fit-content;
                            align-items: center;
                            padding: 0.7rem 1.5rem;
                            justify-content: center;
                            &:hover {
                                color: $primary-color;
                                border: 2px solid $primary-color;
                            }
                        }
                    }
                }
                .segundo_bloque {
                    background-color: $secondary-color;
                    padding-top: 7rem;
                    padding-bottom: 10rem;
                    div.container-fluid.position-relative {
                        @media (max-width: 991px) {
                            padding-left: 4rem;
                            padding-right: 5rem;
                        }
                        .titulo {
                            color: $primary-color;
                            font-size: 2rem;
                        }
                        .div_novedades_destacadas {}
                    }
                }
            }
        }
    }
}

@media (max-width: 1500px) {
    .novedad {
        .layout {
            .main {
                .container-fluid-85 {
                    .primer_bloque {
                        .div_breadcrums {}
                        .div_novedad {
                            .texto_lateral {
                                left: -5.2rem;
                            }
                            .fecha {}
                            .titulo {}
                            .subtitulo {}
                            .publicado_por {}
                            .texto_novedad {}
                            .div_video {}
                            .texto_novedad_2 {}
                            .hr_novedad {}
                            .pills {
                                .pill {}
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .novedad {
        .layout {
            .main {
                .container-fluid-85 {
                    .primer_bloque {
                        .div_breadcrums {}
                        .div_novedad {
                            .texto_lateral {
                                top: 13rem;
                            }
                            .fecha {}
                            .titulo {}
                            .subtitulo {}
                            .publicado_por {}
                            .texto_novedad {}
                            .div_video {}
                            .texto_novedad_2 {}
                            .hr_novedad {}
                            .pills {
                                .pill {}
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 770px) {
    .novedad {
        .layout {
            .main {
                .container-fluid-85 {
                    padding-left: 0;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .novedad {
        .layout {
            .main {
                .container-fluid-85 {
                    .primer_bloque {
                        margin-left: 1rem;
                        .div_breadcrums {}
                        .div_novedad {
                            .texto_lateral {
                                left: -6rem;
                                top: 12rem;
                            }
                            .fecha {}
                            .titulo {}
                            .subtitulo {}
                            .publicado_por {}
                            .texto_novedad {}
                            .div_video {
                                height: 190px;
                            }
                            .texto_novedad_2 {}
                            .hr_novedad {}
                            .pills {
                                .pill {}
                            }
                        }
                    }
                    // .segundo_bloque {
                    //     padding-top: 9vh;
                    // }
                }
            }
        }
    }
}