.projects {
    background: $secondary-color;
    position: relative;
    padding: 5rem 0rem 10rem 0rem;
    .div_breadcrumbs{
        margin-bottom: 5rem;
    }
    img{

    }
    h2{
        color: $fourth-color;
        letter-spacing: 12px;
        text-transform: uppercase;
    }
    .div_btn{
        margin-top: 5rem;

        .button-common{
            text-transform: uppercase;
            text-decoration: none;
            &:hover{
                border-color: $primary-color;
                color: $primary-color;
            }
        }
    }
}

@media (max-width: 992px) {
    .projects {
        
    }
}