.nosotros {
  .content {
    .div_experiencia {
      padding: 9rem 0 9rem 0;
      background-color: $secondary-color;
      .container-fluid{
        &.fluid-cont{
          padding: 4rem 0 4rem 0;
          max-width: 65%;
          margin: auto;
          .number-count{
            position: relative;
            font-size: 9rem;
            color: $primary-color;
            padding-top: 3rem;
            &::before{
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 95%;
              background-color: $primary-color;
              height: 18px;
            }

          }
          .text-count{
            font-size: 2rem;
            color: $primary-color;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .nosotros {
    .content {
      .div_experiencia {
        .container-fluid{
          &.fluid-cont{
            max-width: 80%;
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .nosotros {
    .content {
      .div_experiencia {
        .container-fluid{
          &.fluid-cont{
            .text-count{
              font-size: 2.5rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .nosotros {
    .content {
      .div_experiencia {
        padding: 3rem 0 3rem 0;
        .container-fluid{
          &.fluid-cont{
            .number-count{
              font-size: 6rem;
              &::before{
                height: 10px;
              }
            }
            .text-count{
              font-size: 1.1rem;
            }
          }
        }
      }
    }
  }
}

