footer {
    background: $fourth-color;
    width: 100vw;
    color: $secondary-color;
    font-size: 1.3rem;
    letter-spacing: 0.27px;
    align-items: center;
    .copy {
        font-size: 1.2rem;
        color: $secondary-color;
        font-family: 'Circular Std';
        font-weight: 500;
        letter-spacing: 0.27px;
        opacity: .8;
        align-self: center;
        justify-content: center;
        p{
            margin: 0;
            padding: 0;
        }
        a {
            &.mediahaus-link{
                color: #00a1ff !important;
            }
            &:hover {
                text-decoration: none !important;
            }
             color: $secondary-color;
            text-decoration: none;
        }
        .reservated{
         font-size: .9rem;
        }
    }
}

@media (max-width: 1300px) {
    footer {
        font-size: 1rem;
        .copy {
            font-size: 1.1rem;
            .reservated{
                font-size: .8rem;
            }
        }
    }
}

@media (max-width: 992px) {
    footer {
        .copy .mediacore-link {}
        .copy {
            a {
                &:hover {}
            }
        }
    }
}
